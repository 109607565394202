<template>
  <b-form-group
    class="control-input"
    :label="control.label"
    :label-for="control.id"
    :label-sr-only="control.labelSrOnly"
    :label-cols="control.labelCols"
    :label-cols-sm="control.labelColsSM"
    :label-cols-md="control.labelColsMD"
    :label-cols-lg="control.labelColsLG"
    :label-cols-xl="control.labelColsXL"
    :label-align="control.labelAlign"
    :label-align-sm="control.labelAlignSM"
    :label-align-md="control.labelAlignMD"
    :label-align-lg="control.labelAlignLG"
    :label-align-xl="control.labelAlignXL"
    :class="formGroupClasses"
    :description="control.description"
    :state="controlState"
  >
    <div class="position-relative">
      <component
        :is="control.type === 'password' ? 'c-control-password-viewer' : 'b-input-group'"
        :size="control.size"
        :class="inputGroupClasses"
      >
        <template v-if="$slots.prepend" #prepend><slot name="prepend"/></template>

        <template #default="{ type }">
          <b-input
            :id="control.id"
            :name="control.name"
            :form="control.form"
            :type="control.type === 'hidden' ? 'text' : control.type === 'password' ? type : control.type"
            :data-type="type"
            v-model="control.value"
            :placeholder="control.placeholder"
            :size="control.size"
            :class="controlClasses"
            :readonly="control.readonly"
            :plaintext="control.plaintext"
            :disabled="control.type === 'hidden' ? true : control.disabled"
            :state="controlState"
            :autofocus="control.autofocus"
            :autocomplete="control.autocomplete"
            :pattern="control.pattern"
            :formatter="control.formatter"
            :lazy-formatter="control.lazyFormatter"
            @input="onControlInput"
            @blur="onControlChange(control.value)"
            @focus.native.capture="control.hasFocus = true"
            @blur.native.capture="control.hasFocus = false"
          />
        </template>

        <template v-if="$slots.append" #append><slot name="append"/></template>
      </component>
    </div>

    <b-form-valid-feedback v-if="control.feedbacksValid" :state="controlState">{{ control.feedbacksValid }}</b-form-valid-feedback>
    <b-form-invalid-feedback :state="controlState">{{ controlInvalidFeedbacks }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { guid } from '@/assets/js/helper/guid'

export default {
  name: 'ControlInput',
  props: {
    id: { type: String, default: null },
    name: { type: String, default: '' },
    form: { type: String, default: '' },
    type: { type: String, default: 'text' },
    formGroupClass: { type: [String, Object, Array], default: '' },
    inputGroupClass: { type: [String, Object, Array], default: '' },
    controlClass: { type: [String, Object, Array], default: '' },
    tabindex: { type: [String, Number], default: null },
    label: { type: String, default: '' },
    labelSrOnly: { type: Boolean, default: false },
    labelCols: { type: [Number, String, Boolean], default: null },
    labelColsSM: { type: [Number, String, Boolean], default: null },
    labelColsMD: { type: [Number, String, Boolean], default: null },
    labelColsLG: { type: [Number, String, Boolean], default: null },
    labelColsXL: { type: [Number, String, Boolean], default: null },
    labelAlign: { type: String, default: '' },
    labelAlignSM: { type: String, default: '' },
    labelAlignMD: { type: String, default: '' },
    labelAlignLG: { type: String, default: '' },
    labelAlignXL: { type: String, default: '' },
    value: { type: [String, Number, Array, Object], default: null },
    placeholder: { type: String, default: '' },
    description: { type: String, default: '' },
    size: { type: String, default: '' },
    dirty: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    readonly: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    autocomplete: { type: String, default: null },
    pattern: { type: String, default: null },
    formatter: { type: Function, default: undefined },
    lazyFormatter: { type: Boolean, default: false },
    validations: { type: Object, default: () => ({}) },
    feedbacksValid: { type: String, default: '' },
    feedbacksInvalid: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      control: {
        id: this.$props.id || guid(),
        name: this.$props.name,
        form: this.$props.form,
        type: this.$props.type,
        formGroupClass: this.$props.formGroupClass,
        inputGroupClass: this.$props.inputGroupClass,
        controlClass: this.$props.controlClass,
        tabindex: this.$props.tabindex,
        label: this.$props.label,
        labelSrOnly: this.$props.labelSrOnly,
        labelCols: this.$props.labelCols,
        labelColsSM: this.$props.labelColsSM,
        labelColsMD: this.$props.labelColsMD,
        labelColsLG: this.$props.labelColsLG,
        labelColsXL: this.$props.labelColsXL,
        labelAlign: this.$props.labelAlign,
        labelAlignSM: this.$props.labelAlignSM,
        labelAlignMD: this.$props.labelAlignMD,
        labelAlignLG: this.$props.labelAlignLG,
        labelAlignXL: this.$props.labelAlignXL,
        value: this.$props.value,
        placeholder: this.$props.placeholder,
        description: this.$props.description,
        size: this.$props.size,
        dirty: this.$props.dirty,
        state: this.$props.state,
        readonly: this.$props.readonly,
        plaintext: this.$props.plaintext,
        disabled: this.$props.disabled,
        autofocus: this.$props.autofocus,
        autocomplete: this.$props.autocomplete,
        pattern: this.$props.pattern,
        formatter: this.$props.formatter,
        lazyFormatter: this.$props.lazyFormatter,
        validations: this.$props.validations,
        feedbacksValid: this.$props.feedbacksValid,
        feedbacksInvalid: this.$props.feedbacksInvalid,
        hasFocus: false
      }
    }
  },
  validations () {
    return {
      control: {
        value: this.control.validations
      }
    }
  },
  computed: {
    validator () {
      return this.$v.control.value
    },
    controlCanValidate () {
      if (this.validator !== undefined) return this.validator.$model !== undefined ? Object.keys(this.validator.$params).length > 0 : false
      return false
    },
    controlState () {
      if (this.controlCanValidate) return this.validator.$dirty ? !this.validator.$error : null
      if (this.control.state !== null) return this.control.dirty ? this.control.state : null
      return this.control.dirty ? true : null
    },
    controlInvalidFeedbacks () {
      if (this.controlCanValidate) {
        const validations = Object.keys(this.validator.$params || {})
        const feedbacks = this.feedbacksInvalid || {}
        const error = validations.find(key => this.validator[key] === false) || ''

        return this.validator.$error ? feedbacks[error] instanceof Function ? feedbacks[error]() : feedbacks[error] || '' : ''
      }

      if (this.control.dirty && !this.control.state) {
        return this.feedbacksInvalid.error || ''
      }

      return ''
    },
    formGroupClasses () {
      return [
        {
          'has-focus': this.control.hasFocus,
          'has-prepend': this.$slots.prepend,
          'has-append': this.$slots.append,
          'is-filled': this.control.value !== undefined && this.control.value !== null && this.control.value !== '',
          'is-readonly': this.control.readonly,
          'is-plaintext': this.control.plaintext,
          'is-disabled': this.control.disabled,
          'is-dirty': this.controlCanValidate ? this.validator.$dirty : false
        }
      ].concat(this.control.formGroupClass || [])
    },
    inputGroupClasses () {
      return [
        {
          'mb-0': this.control.label === ''
        }
      ].concat(this.control.formInputClass || [])
    },
    controlClasses () {
      return [
        {
          'd-none': this.control.type === 'hidden'
        }
      ].concat(this.control.controlClass || [])
    }
  },
  methods: {
    setDirty (dirty) {
      if (this.controlCanValidate) {
        if (dirty) {
          this.validator.$touch()
        } else {
          this.validator.$reset()
        }
      }
    },
    onControlInput (controlValue) {
      if (this.controlCanValidate && this.validator.$dirty) {
        this.validator.$model = controlValue
        this.validator.$touch()
      }

      this.control.state = null

      this.$emit('input', controlValue)
    },
    onControlChange (controlValue) {
      if (this.controlCanValidate) {
        this.validator.$model = controlValue
        this.validator.$touch()
      }

      this.control.state = null

      this.$emit('change', controlValue)
    },
    setTabindex () {
      const input = this.$el.querySelector('input')
      if (input) input.setAttribute('tabindex', this.control.tabindex)
    }
  },
  created () {
    this.setDirty(this.control.dirty)
  },
  mounted () {
    this.$nextTick(() => {
      this.setTabindex()
    })
  },
  watch: {
    '$props.id' (id) { this.control.id = id },
    '$props.name' (name) { this.control.name = name },
    '$props.form' (form) { this.control.form = form },
    '$props.type' (type) { this.control.type = type },
    '$props.formGroupClass' (formGroupClass) { this.control.formGroupClass = formGroupClass },
    '$props.inputGroupClass' (inputGroupClass) { this.control.inputGroupClass = inputGroupClass },
    '$props.controlClass' (controlClass) { this.control.controlClass = controlClass },
    '$props.tabindex' (tabindex) { this.control.tabindex = tabindex },
    '$props.label' (label) { this.control.label = label },
    '$props.labelSrOnly' (labelSrOnly) { this.control.labelSrOnly = labelSrOnly },
    '$props.labelCols' (labelCols) { this.control.labelCols = labelCols },
    '$props.labelColsSM' (labelColsSM) { this.control.labelColsSM = labelColsSM },
    '$props.labelColsMD' (labelColsMD) { this.control.labelColsMD = labelColsMD },
    '$props.labelColsLG' (labelColsLG) { this.control.labelColsLG = labelColsLG },
    '$props.labelColsXL' (labelColsXL) { this.control.labelColsXL = labelColsXL },
    '$props.labelAlign' (labelAlign) { this.control.labelAlign = labelAlign },
    '$props.labelAlignSM' (labelAlignSM) { this.control.labelAlignSM = labelAlignSM },
    '$props.labelAlignMD' (labelAlignMD) { this.control.labelAlignMD = labelAlignMD },
    '$props.labelAlignLG' (labelAlignLG) { this.control.labelAlignLG = labelAlignLG },
    '$props.labelAlignXL' (labelAlignXL) { this.control.labelAlignXL = labelAlignXL },
    '$props.value' (value) { this.control.value = value },
    '$props.placeholder' (placeholder) { this.control.placeholder = placeholder },
    '$props.description' (description) { this.control.description = description },
    '$props.size' (size) { this.control.size = size },
    '$props.dirty' (dirty) {
      this.control.dirty = dirty
      this.setDirty(this.control.dirty)
    },
    '$props.state' (state) { this.control.state = state },
    '$props.readonly' (readonly) { this.control.readonly = readonly },
    '$props.plaintext' (plaintext) { this.control.plaintext = plaintext },
    '$props.disabled' (disabled) { this.control.disabled = disabled },
    '$props.autofocus' (autofocus) { this.control.autofocus = autofocus },
    '$props.autocomplete' (autocomplete) { this.control.autocomplete = autocomplete },
    '$props.pattern' (pattern) { this.control.pattern = pattern },
    '$props.formatter' (formatter) { this.control.formatter = formatter },
    '$props.lazyFormatter' (lazyFormatter) { this.control.lazyFormatter = lazyFormatter },
    '$props.validations' (validations) { this.control.validations = validations },
    '$props.feedbacksValid' (feedbacksValid) { this.control.feedbacksValid = feedbacksValid },
    '$props.feedbacksInvalid' (feedbacksInvalid) { this.control.feedbacksInvalid = feedbacksInvalid },
    'control.tabindex' (tabindex) { this.setTabindex() }
  }
}
</script>

<style lang="scss">
.control-input {}
</style>
