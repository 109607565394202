<template>
  <div class="checkout-step">
    <h1 class="h4 mb-4"><slot name="title"/></h1>

    <loader v-if="is.initial"/>

    <b-row v-else>
      <b-col :[$root.md.key]="8" tag="article">
        <b-alert variant="danger" :show="!step.isLoading && step.isDirty && stepHasErrors">
          <h2 class="h5 alert-heading"><lazy-icon icon="b-exclamation-circle"/> {{ $t(`${tPath}.errors.title`) }}</h2>

          <div>
            <div v-for="(configurationProperty, pKey) in stepErrors" :key="pKey" class="error-group">
              <div v-for="(error, eIndex) in configurationProperty" :key="`${pKey}_${eIndex}`" class="error">
                {{ error[$store.getters['gui/language:get']] }}
              </div>
            </div>
          </div>
        </b-alert>

        <div>
          <slot v-bind="{configuration: configuration, basket: basket}" />
        </div>
      </b-col>

      <b-col :[$root.md.key]="4" tag="aside" :class="{ 'mt-3': !$root.isDesktop }">
        <checkout-summary>
          <template #title>
            {{ $t(`${tPath}.summary.title`) }}
          </template>

          <template #content>
            <slot name="summaryfooter" v-bind="{ basketId: basket.id, submit }"/>
          </template>
        </checkout-summary>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP } from '@/constants'

import Loader from '@/components/private/Loader'
import CheckoutSummary from '@/components/private/CheckoutSummary'

export default {
  name: 'CheckoutStep',
  components: {
    Loader,
    CheckoutSummary
  },
  props: {
    validationSections: {
      type: Array,
      default: () => ([])
    },
    initialOptionValues: {
      type: Object,
      default: () => ({})
    },
    gtmOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      step: {
        isInitialized: false,
        isDirty: false,
        isLoading: false,
        optionRegistration: {
          timeout: null,
          debounce: 500
        }
      },
      optionsActive: this.initialOptionValues,
      optionsData: {},
      initialLoad: true,
      enableGtmUpdates: false
    }
  },
  computed: {
    is () {
      return this.$store.getters['shoppingcart/is']
    },
    basket () {
      return this.$store.getters['shoppingcart/get']
    },
    configuration () {
      return this.$store.getters['shoppingcart/getConfiguration']
    },
    summary () {
      const priceDetails = this.$store.getters['shoppingcart/getSummary']

      return []
        .concat(priceDetails.priceLines || [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel || {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$store.getters['gui/language:get']]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    },
    stepOptions () {
      return Object.values(this.optionsData)
    },
    stepFormData () {
      return this.stepOptions.reduce((formData, o) => Object.assign(formData, { [o.option.name]: o.option.value }, o.formData), {})
    },
    stepValidationSections () {
      return this.validationSections.map(sKey => this.$store.getters['shoppingcart/getStepValidationResult'](sKey))
    },
    stepErrors () {
      return this.stepValidationSections.reduce((errors, v) => Object.assign(errors, v.errors), {})
    },
    stepHasErrors () {
      return Object.keys(this.stepErrors).length > 0
    },
    stepIsValid () {
      return this.stepOptions.reduce((valid, o) => o.$v.$invalid ? false : valid, true) && this.stepValidationSections.reduce((valid, s) => !s.isValid ? false : valid, true)
    }
  },
  methods: {
    validateStep () {
      this.stepOptions.forEach(g => g.$v.$touch())
      this.$emit('checkoutstep:validate')
    },
    resetStepValidation () {
      this.step.isDirty = false
      this.stepOptions.forEach(g => g.$v.$reset())
    },
    enableGtmEvents () {
      this.enableGtmUpdates = true
    },
    updateStep (data = {}) {
      if (this.step.optionRegistration.timeout) clearTimeout(this.step.optionRegistration.timeout)

      this.step.optionRegistration.timeout = setTimeout(() => {
        this.$set(this.optionsData, data.option.name, data)

        if (this.step.isInitialized) {
          this.$store.dispatch('shoppingcart/update', this.stepFormData)

          if (this.$store.getters['gtm/isAvailable'] && this.enableGtmUpdates) {
            this.$store.dispatch('gtm/checkout', {
              step: this.gtmOptions.step,
              option: this.optionsActive.deliveryType || this.optionsActive.paymentType || undefined,
              list: this.gtmOptions.list,
              products: this.basket.items.map(i => Object.assign({}, i.product, { quantity: i.quantity }))
            })
            this.enableGtmUpdates = false
          }
        }

        this.step.isInitialized = true
      }, this.step.optionRegistration.debounce)
    },
    refreshStep (data = {}) {
      this.$store.dispatch('shoppingcart/get')
    },
    submit (e) {
      e.preventDefault()

      this.step.isDirty = true
      this.step.isLoading = true
      const targetHref = e.target.href

      this.$store.dispatch('shoppingcart/update', this.stepFormData)
        .then(() => {
          this.validateStep()

          if (this.stepIsValid) {
            if (this.$store.getters['gtm/isAvailable']) {
              this.$store.dispatch('gtm/checkout', {
                step: this.gtmOptions.step,
                option: this.optionsActive.deliveryType || this.optionsActive.paymentType || undefined,
                list: this.gtmOptions.list,
                products: this.basket.items.map(i => Object.assign({}, i.product, { quantity: i.quantity })),
                callback: () => {
                  this.step.isLoading = false
                  window.location.assign(targetHref)
                }
              })
            } else {
              this.step.isLoading = false
              window.location.assign(targetHref)
            }
          } else {
            // scroll to top of .checkout-step div
            const step = document.querySelector('.checkout-step')
            if (step) step.scrollIntoView({ behavior: 'smooth' })
          }
        })
        .finally(() => {
          this.step.isLoading = false
        })
    },
    gtm () {
      if (this.basket.items && this.gtmOptions.step !== undefined) {
        this.$store.dispatch('gtm/checkout', {
          step: this.gtmOptions.step,
          list: this.gtmOptions.list,
          products: this.basket.items.map(i => Object.assign({}, i.product, { quantity: i.quantity }))
        })
      }
    }
  },
  created () {
    this.$store.dispatch('shoppingcart/get')

    this.$on('optionform:enablegtm', this.enableGtmEvents)
    this.$on('optionform:change', this.updateStep)
    this.$on('coupon:change', this.refreshStep)
  },
  watch: {
    configuration: {
      immediate: true,
      deep: true,
      handler () {
        Object.keys(this.initialOptionValues)
          .forEach(oKey => {
            if (!this.$root.isNullOrEmpty(this.configuration[oKey])) this.optionsActive[oKey] = this.configuration[oKey]
          })
      }
    },
    optionsActive: {
      deep: true,
      handler () {
      }
    },
    basket: {
      deep: false,
      handler () {
        if (this.initialLoad) {
          this.gtm()
          this.initialLoad = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
$checkoutstep-alert-heading-border: set-nth(map-get($borders, 'base'), 3, theme-color-level('danger', $alert-border-level)) !default;
$checkoutstep-alert-heading-border-gap: $spacer * 0.5 !default;
$checkoutstep-alert-errorgroup-padding-y: $spacer * 0.5 !default;
$checkoutstep-alert-errorgroup-padding-x: 0 !default;

.checkout-step {
  @include component('y');

  .alert {
    .alert-heading {
      padding-bottom: $checkoutstep-alert-heading-border-gap;
      border-bottom: $checkoutstep-alert-heading-border;
    }

    .error-group {
      padding: $checkoutstep-alert-errorgroup-padding-y $checkoutstep-alert-errorgroup-padding-x;

      .error {}

      &:first-child { padding-top: 0; }
      &:last-child { padding-bottom: 0; }
    }
  }
}
</style>
