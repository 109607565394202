<template>
  <b-alert :show="show" variant="danger">
    <h5>Unknown Control</h5>
    <pre class="text-inherit">{{ properties }}</pre>
    <input class="form-control" type="hidden"/>
  </b-alert>
</template>

<script>
export default {
  name: 'ControlUnknown',
  data () {
    return {
      show: process.env.NODE_ENV === 'development',
      properties: this.$vnode.data.attrs
    }
  }
}
</script>

<style lang="scss"></style>
