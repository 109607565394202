<template>
  <div class="checkout-coupon">

    <div class="loader-container" v-if="isLoading">
      <loader v-if="isLoading"/>
    </div>

    <c-control-validator
      :validator="$v.control.value"
      v-slot="validator"
    >
      <b-form-group
        :label="$t(`${tPath}.control.label`)"
        label-for="CheckoutCouponInput"
        label-class="font-bold"
        :valid-feedback="validator.feedback"
        :invalid-feedback="validator.feedback"
        :state="validator.state"
      >
        <b-input-group>
          <b-form-input
            id="CheckoutCouponInput"
            v-model="control.value"
            :state="validator.state"
          />

          <template #append>
            <b-button variant="outline-primary" @click="registerCode">{{ $t(`${tPath}.control.button`) }}</b-button>
          </template>
        </b-input-group>

        <b-form-invalid-feedback :state="validator.state" v-if="$v.control.$model.feedbacks.errors.servererror">
          <template v-if="$v.control.$model.feedbacks.errors.servererror.includes('<')">
            <span v-html="$v.control.$model.feedbacks.errors.servererror"></span>
          </template>
          <template v-else>
            {{ $v.control.$model.feedbacks.errors.servererror }}
          </template>
      </b-form-invalid-feedback>
      </b-form-group>
    </c-control-validator>

    <div v-if="redeemedCoupons" class="coupon-registered">
      <label id="CheckoutCouponRegistered" class="h6">{{ $t(`${tPath}.list.label`) }}</label>

      <ul class="redeemed-coupon-list">
        <li v-for="coupon in redeemedCoupons" :key="coupon.code" class="redeemed-coupon d-sm-flex flex-row">
          <div class="coupon-code text-break">{{ coupon.code }}</div>
          <div class="coupon-description flex-grow-1">
            <p class="text-success mb-0">{{ coupon.name }}</p>
            <p v-if="coupon.balance">
              {{ $t(`${tPath}.balance`) }}
              <i18n-n
                tag="span"
                :value="coupon.balance"
                :format="{ key: 'currency', currency: coupon.currency }"
                class="price"
              >
                <template #currency="slotProps"><span :class="['currency']">{{ slotProps.currency }}</span></template>
                <template #integer="slotProps"><span class="integer">{{ slotProps.integer }}</span></template>
                <template #group="slotProps"><span class="group">{{ slotProps.group }}</span></template>
                <template #decimal="slotProps"><span class="decimal">{{ slotProps.decimal }}</span></template>
                <template #fraction="slotProps"><span class="fraction">{{ slotProps.fraction }}</span></template>
              </i18n-n>
            </p>
          </div>
          <div class="coupon-action">
            <a @click="removeCode(coupon)" class="delete"><lazy-icon icon="trash"/></a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CheckoutStep from '@/components/public/order/CheckoutStep'
import Loader from '@/components/private/Loader'

import { getNamedParentContext } from '@/assets/js/helper/vm'

export default {
  name: 'CheckoutCoupon',
  components: {
    Loader
  },
  props: {
    basket: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      control: {
        value: '',
        validators: {
          coupon: value => true
        },
        feedbacks: {
          success: null,
          errors: {
            coupon: this.$t(`${this.tPath}.control.error.invalid`)
          }
        }
      }
    }
  },
  validations () {
    const validators = this.control.validators

    return {
      control: {
        value: Object.keys(validators)
          .reduce((v, vKey) => Object.assign(v, { [vKey]: typeof validators[vKey] === 'function' ? value => validators[vKey](value, this.form) : validators[vKey] }), {})
      }
    }
  },
  computed: {
    redeemedCoupons () {
      return this.basket.redeemedCoupons
    }
  },
  methods: {
    registerCode () {
      this.isLoading = true
      if (this.control.validators.servererror) {
        this.$delete(this.control.validators, 'servererror')
        this.$delete(this.control.feedbacks.errors, 'servererror')
      }

      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$http({
          method: 'put',
          url: `/order/api/VoucherAndGiftCards/use/${this.control.value}/onbasket/${this.basket.id}`
        })
          .then(response => {
            if (response.data.result.success) {
              this.control.value = ''
              this.$v.$reset()
              this.onChange()
            } else {
              const errorMessageFormat = this.$t(`${this.tPath}.control.error.${response.data.result.state.toLowerCase()}`)
              const formatData = response.data.result.formatData
              const errorMessage = this.formatMessage(errorMessageFormat, formatData)

              this.$set(this.control.validators, 'servererror', value => false)
              this.$set(this.control.feedbacks.errors, 'servererror', errorMessage)
            }
          })
          .catch(error => {
            const errorMessageFormat = this.$t(`${this.tPath}.control.error.${error.response.data.result.state.toLowerCase()}`)
            const formatData = error.response.data.result.formatData
            const errorMessage = this.formatMessage(errorMessageFormat, formatData)

            this.$set(this.control.validators, 'servererror', value => false)
            this.$set(this.control.feedbacks.errors, 'servererror', errorMessage)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    removeCode (coupon = {}) {
      this.$http({
        method: 'delete',
        url: `/order/api/VoucherAndGiftCards/remove/${coupon.code}/frombasket/${this.basket.id}`
      })
        .then(response => {
          this.onChange()
        })
    },
    formatMessage (message, formatObject) {
      if (!formatObject) {
        return message
      }

      return Object.getOwnPropertyNames(formatObject).reduce((acc, key) => {
        return acc.replace(`{${key}}`, formatObject[key])
      }, message)
    },
    controlInvalidFeedbacks () {
      return this.control.feedbacks.errors()
    },
    onChange () {
      this.stepContext.$emit('coupon:change')
    }
  },
  beforeCreate () {
    this.stepContext = getNamedParentContext(CheckoutStep.name, this)
  },
  created () {
    this.onChange()
  }
}
</script>

<style lang="scss">
$checkoutcoupon-padding-y: $spacer * 1.25 !default;
$checkoutcoupon-padding-x: $checkoutcoupon-padding-y !default;
$checkoutcoupon-border: map-get($borders, 'bold') !default;
$checkoutcoupon-border-radius: $border-radius-lg !default;

.checkout-coupon {
  padding: $checkoutcoupon-padding-y $checkoutcoupon-padding-x;
  border: $checkoutcoupon-border;
  border-radius: $checkoutcoupon-border-radius;

  position: relative;

  .loader-container {
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(204, 204, 204, 0.8);

    .loader {
      height: 100%;
    }
  }

  .coupon-registered {
    ul {
      @include list-unstyled();
    }

    .redeemed-coupon-list {
      .redeemed-coupon {
        position:relative;

        .coupon-code {
          width: 25%;
        }

        .coupon-description {
        }

        .coupon-action {
          a {
            cursor: pointer;
          }
        }
      }
    }

    @include media-breakpoint-down(get-breakpoint-key($tablet-breakpoint, -2)) {
      .redeemed-coupon-list {
        .redeemed-coupon {
          padding-bottom: $checkoutcoupon-padding-y;
          margin-bottom: $checkoutcoupon-padding-y;
          border-bottom: $checkoutcoupon-border;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
          }

          .coupon-code {
            width: 100%;
          }

          .coupon-action {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
}
</style>
