<template>
  <c-info-container class="checkout-summary">
    <h6><slot name="title"/></h6>

    <dl class="summary-list" v-if="!is.initial">
      <component
        v-for="(cost, cIndex) in summary"
        :key="cIndex"
        :is="cost.component"
        :text="cost.label"
        :amount="cost.price"
        v-bind="cost.attrs"
      />
    </dl>

    <loader v-if="is.initial" variant="primary"/>
    <slot v-else name="content"/>
  </c-info-container>
</template>

<script>
import { COMPONENT_BASKET_MODULES, COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP } from '@/constants'

import Loader from '@/components/private/Loader'
import SummaryPrice from '@/components/private/SummaryPrice'
import SummarySeparator from '@/components/private/SummarySeparator'

const DEFAULT_MODULENAME = Object.keys(COMPONENT_BASKET_MODULES).find(mKey => COMPONENT_BASKET_MODULES[mKey].isDefault)

export default {
  name: 'CheckoutSummary',
  components: {
    Loader,
    SummaryPrice,
    SummarySeparator
  },
  props: {
    moduleName: {
      type: String,
      default: DEFAULT_MODULENAME
    },
    preloadedBasket: {
      type: Object,
      default: null
    }
  },
  computed: {
    module () {
      return Object.assign({}, COMPONENT_BASKET_MODULES.base, COMPONENT_BASKET_MODULES[this.moduleName] ?? {})
    },
    is () {
      return this.module.isPreloaded ? {} : this.$store.getters[`${this.module.name}/is`]
    },
    basket () {
      return this.module.isPreloaded ? this.preloadedBasket : this.$store.getters[`${this.module.name}/${this.module.getters.basket}`]
    },
    summary () {
      const priceDetails = this.basket.priceDetail ?? {}

      return []
        .concat(priceDetails.priceLines ?? [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel ?? {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$store.getters['gui/language:get']]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type || p.showSeparator ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    }
  }
}
</script>

<style lang="scss">
.checkout-summary {}
</style>
