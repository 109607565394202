<template functional>
  <div :class="['loader', { 'is-inline': props.inline }]">
    <b-spinner :type="type" :variant="variant" :small="small" :label="label"/>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'border'
    },
    variant: {
      type: String,
      default: 'light'
    },
    small: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss">
$loader-padding-y: $component-padding-y !default;
$loader-padding-x: $component-padding-y !default;

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $loader-padding-y $loader-padding-x;

  &.is-inline {
    display: inline-block;
    padding: 0;
    margin-top: -0.1em;
    vertical-align: middle;

    > span {
      display: block;
      width: 1.2em;
      height: 1.2em;
      vertical-align: middle;
    }
  }
}
</style>
