<template>
  <div class="order">
    <c-basket moduleName="checkoutoverview" :translationsPath="tPath">

      <template #aftertitle="slotProps">
        <b-alert v-if="!slotProps.isLoading" variant="danger" :show="!orderValidation.isValid">
          <h2 class="h5 alert-heading"><lazy-icon icon="b-exclamation-circle"/> {{ $t(`${tPath}.errors.title`) }}</h2>

          <div v-for="(error, eIndex) in orderValidation.validationErrors" :key="`OrderValidationError_${eIndex}`" class="error-group">
            {{ error[$store.getters['gui/language:get']] }}
          </div>
        </b-alert>
        <b-alert v-if="paymentError.length > 0" variant="danger" :show="true">
          <h2 class="h5 alert-heading"><lazy-icon icon="b-exclamation-circle"/> {{ $t(`${tPath}.paymenterrors.title`) }}</h2>

          <div class="error-group">
            {{ paymentError }}
          </div>
        </b-alert>
      </template>

      <template #summaryfooter>
        <slot name="summaryfooter" v-bind="{ basketId: basket.id, submit }"/>
      </template>
    </c-basket>

    <order-configuration :redeemedCoupons="basket.redeemedCoupons" :isReseller="isReseller" :isMithilfeAblad="basket.isMithilfeAblad" :isStoreClerk="isStoreClerk" :configuration="orderConfiguration" :translationsPath="`${tPath}.configuration`"/>
  </div>
</template>

<script>
import OrderConfiguration from '@/components/private/OrderConfiguration'

export default {
  name: 'Order',
  components: {
    OrderConfiguration
  },
  props: {
    isReseller: {
      type: Boolean,
      default: false
    },
    isStoreClerk: {
      type: Boolean,
      default: false
    },
    paymentError: {
      type: String,
      default: null
    }
  },
  computed: {
    order () {
      return this.$store.getters['checkout/get']
    },
    basket () {
      return this.$store.getters['checkout/getBasket']
    },
    orderConfiguration () {
      return Object.assign({}, this.basket.configuration ?? {}, this.$store.getters['checkout/getConfiguration'] ?? {})
    },
    orderValidation () {
      return this.$store.getters['checkout/getValidation']
    }
  },
  methods: {
    submit (e) {
      e.preventDefault()

      this.$store.dispatch('checkout/execute')
        .then(response => {
          if (this.order.isOrderValid) {
            window.location.assign(e.target.href)
          }
        })
        .catch(response => {
        })
    }
  }
}
</script>

<style lang="scss">
$checkoutstep-alert-heading-border: set-nth(map-get($borders, 'base'), 3, theme-color-level('danger', $alert-border-level)) !default;
$checkoutstep-alert-heading-border-gap: $spacer * 0.5 !default;
$checkoutstep-alert-errorgroup-padding-y: $spacer * 0.5 !default;
$checkoutstep-alert-errorgroup-padding-x: 0 !default;

.order {
  .alert {
    .alert-heading {
      padding-bottom: $checkoutstep-alert-heading-border-gap;
      border-bottom: $checkoutstep-alert-heading-border;
    }

    .error-group {
      padding: $checkoutstep-alert-errorgroup-padding-y $checkoutstep-alert-errorgroup-padding-x;

      .error {}

      &:first-child { padding-top: 0; }
      &:last-child { padding-bottom: 0; }
    }
  }
}
</style>
