<template>
  <address-line v-if="!basketIs.initial && !userIs.initial" :user="user" :address="userAddress" inline/>
</template>

<script>
import { USER_ADDRESS_TYPES } from '@/constants'

import AddressLine from '@/components/private/AddressLine'

const USERADDRESS_PROPERTY_KEYS = Object.values(USER_ADDRESS_TYPES).map(t => t.checkoutproperties.key)

export default {
  name: 'CheckoutUserAddress',
  components: {
    AddressLine
  },
  props: {
    addressId: {
      type: String,
      default: null
    },
    predefined: {
      type: Object,
      default: () => ({
        user: null,
        address: null
      })
    }
  },
  computed: {
    basketIs () {
      return this.$store.getters['shoppingcart/is']
    },
    basketConfiguration () {
      return this.$store.getters['shoppingcart/getConfiguration']
    },
    userIs () {
      return this.$store.getters['users/is']
    },
    hasRegistrationData () {
      return this.basketConfiguration.registrationType !== undefined
    },
    user () {
      if (this.predefined.user !== null) {
        return this.predefined.user
      } else {
        if (this.basketConfiguration.existingUser && this.basketConfiguration.existingUser.id) {
          return this.basketConfiguration.existingUser
        }

        return this.hasRegistrationData ? this.basketConfiguration.newUser || {} : this.$store.getters['users/getEntityUnwrapped'](this.basketConfiguration.userId)
      }
    },
    userAddress () {
      if (this.predefined.address !== null) {
        return this.predefined.address
      } else {
        if (this.basketConfiguration.existingUserAddress && this.basketConfiguration.existingUserAddress.id === this.addressId) {
          return this.basketConfiguration.existingUserAddress
        }

        return this.hasRegistrationData ? this.basketConfiguration[this.addressId] : this.$store.getters['useraddresses/getUnwrapped'].find(a => a.id === this.addressId) || {}
      }
    }
  },
  created () {
    if (!USERADDRESS_PROPERTY_KEYS.includes(this.addressId) && this.addressId !== null) this.$store.dispatch('useraddresses/getEntity', this.addressId)
  }
}
</script>

<style lang="scss"></style>
