<template>
  <b-form-radio-group
    class="checkout-user-addresses"
    v-model="selected"
    stacked
    v-bind="$attrs"
  >
    <div v-if="basketIs.initial || userIs.initial || userAddressesIs.initial" class="user-address">
      <loader variant="primary" inline/>
    </div>

    <template v-else>
      <div class="user-address radio-centered" v-for="userAddress in userAddresses" :key="userAddress.id">
        <b-form-radio :value="userAddress.id">
          <c-checkout-user-address :addressId="userAddress.id"/>
        </b-form-radio>
      </div>

      <div class="user-address">
        <b-form-radio :value="addUserAddressOption.value">
          <div class="address-add"><lazy-icon icon="add" scale="0.85"/> {{ $t(`${tPath}.adduseraddress.label`) }}</div>

          <entity-form
            v-if="addUserAddressSelected"
            :id="addUserAddressOption.formId"
            entityKey="useraddresses"
            :showButtons="true"
            class="address-add-form"
            :buttonVariants="{ submit: 'outline-primary' }"
            :buttonSizes="{ submit: 'xs' }"
            @form:submit="addUserAddress"
          >
            <template #submit>
              {{ $t(`${tPath}.adduseraddress.savebutton`) }}
            </template>
          </entity-form>
        </b-form-radio>
      </div>
    </template>
  </b-form-radio-group>
</template>

<script>
import { USER_ADDRESS_TYPES } from '@/constants'

import { unwrap } from '@/assets/js/helper/entity'

import Loader from '@/components/private/Loader'
import EntityForm from '@/components/private/forms/EntityForm'

export default {
  name: 'CheckoutUserAddresses',
  components: {
    Loader,
    EntityForm
  },
  props: {
    value: {
      required: true
    },
    userAddressType: {
      type: String,
      default: USER_ADDRESS_TYPES.default.type
    },
    targetUserId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selected: this.value,
      addUserAddressOption: {
        value: null,
        formId: 'Form_CheckoutAddUserAddress'
      }
    }
  },
  computed: {
    basketIs () {
      return this.$store.getters['shoppingcart/is']
    },
    basketConfiguration () {
      return this.$store.getters['shoppingcart/getConfiguration']
    },
    userIs () {
      return this.$store.getters['users/is']
    },
    user () {
      return this.$store.getters['users/getEntityUnwrapped'](this.targetUserId)
    },
    userAddressesIs () {
      return this.$store.getters['useraddresses/is']
    },
    userAddresses () {
      return this.$store.getters['useraddresses/getUnwrapped']
    },
    addUserAddressSelected () {
      return this.selected === this.addUserAddressOption.value
    }
  },
  methods: {
    getUserAddresses () {
      if (this.targetUserId) {
        this.$store.dispatch('users/getEntity', this.targetUserId)
        this.$store.dispatch('useraddresses/getAll', { by: { name: 'users', id: this.targetUserId } })
      }
    },
    addUserAddress (formData) {
      if (formData.isValid) {
        this.$store.dispatch('useraddresses/createEntity', Object.assign({ type: this.addressType, userId: this.targetUserId }, formData.controls))
          .then(response => {
            this.selected = unwrap(response).id
          })
          .finally(() => {
            if (this.addUserAddressSelected) {
              this.selected = (this.userAddresses.find(a => a.type === this.addressType) || this.userAddresses[0]).id
            }

            this.$root.$emit(`${this.addUserAddressOption.formId}:reset`)
          })
      }
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler () {
        this.$emit('input', this.selected)
      }
    },
    targetUserId: {
      immediate: true,
      handler (newUserId = {}, oldUserId = {}) {
        if (newUserId !== oldUserId) this.getUserAddresses()
      }
    },
    userAddresses: {
      immediate: true,
      deep: true,
      handler () {
        const selectUserAddress = this.userAddresses.find(a => a.type === this.userAddressType) || this.userAddresses[0] || {}
        if (this.selected === null && selectUserAddress.id) this.selected = selectUserAddress.id
      }
    }
  }
}
</script>

<style lang="scss">
$checkoutuseraddresses-address-gap: $spacer * 0.25 !default;
$checkoutuseraddresses-address-padding-y: $spacer * 0.75 !default;
$checkoutuseraddresses-address-padding-x: $checkoutuseraddresses-address-padding-y !default;
$checkoutuseraddresses-address-bg: $beige !default;
$checkoutuseraddresses-address-border-radius: $border-radius !default;
$checkoutuseraddresses-address-line-height: 1.2 !default;

$checkoutuseraddresses-address-add-color: $link-color !default;
$checkoutuseraddresses-address-add-text-decoration: $link-decoration !default;

$checkoutuseraddresses-address-form-gap: $spacer !default;

.checkout-user-addresses {
  .user-address {
    padding: $checkoutuseraddresses-address-padding-y $checkoutuseraddresses-address-padding-x;
    background-color: $checkoutuseraddresses-address-bg;
    border-radius: $checkoutuseraddresses-address-border-radius;
    line-height: $checkoutuseraddresses-address-line-height;

    .custom-radio {
      min-height: $checkoutuseraddresses-address-line-height * 1rem;

      .custom-control-input {
        ~ .custom-control-label {
          &:before,
          &:after {
            top: 0;
          }
        }
      }
    }

    &.radio-centered {
      .custom-radio {
        .custom-control-input {
          ~ .custom-control-label {
            margin: 0;

            &:before,
            &:after {
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    .address-add {
      color: $checkoutuseraddresses-address-add-color;
      text-decoration: $checkoutuseraddresses-address-add-text-decoration;
    }

    .address-add-form {
      margin-top: $checkoutuseraddresses-address-form-gap;
    }

    + .user-address {
      margin-top: $checkoutuseraddresses-address-gap;
    }
  }
}
</style>
