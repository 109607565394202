<template>
  <b-form-group
    class="control-checkbox"
    :label="control.label"
    :label-for="control.id"
    :label-sr-only="control.labelSrOnly"
    :label-cols="control.labelCols"
    :label-cols-sm="control.labelColsSM"
    :label-cols-md="control.labelColsMD"
    :label-cols-lg="control.labelColsLG"
    :label-cols-xl="control.labelColsXL"
    :label-align="control.labelAlign"
    :label-align-sm="control.labelAlignSM"
    :label-align-md="control.labelAlignMD"
    :label-align-lg="control.labelAlignLG"
    :label-align-xl="control.labelAlignXL"
    :class="formGroupClasses"
    :description="control.description"
    :state="controlState"
  >
    <b-form-checkbox-group
      v-if="control.options"
      :id="control.id"
      :name="control.name"
      :form="control.form"
      v-model="control.value"
      :options="control.options"
      :size="control.size"
      :class="controlClasses"
      :plain="control.plain"
      :disabled="control.disabled"
      :stacked="control.stacked"
      :buttons="control.button"
      :buttonVariant="control.buttonVariant"
      :switches="control.switch"
      :state="controlState"
      :autofocus="control.autofocus"
      @input="onControlInput"
      @change="onControlChange"
    >
      <template #first>
        <span v-if="control.text || $slots.text" class="control-text">
          <slot name="text"><span v-html="control.text"/></slot>
        </span>
      </template>
    </b-form-checkbox-group>

    <b-form-checkbox
      v-else
      :id="control.id"
      :name="control.name"
      :form="control.form"
      v-model="control.value"
      :size="control.size"
      :class="controlClasses"
      :plain="control.plain"
      :disabled="control.disabled"
      :stacked="control.stacked"
      :buttons="control.button"
      :buttonVariant="control.buttonVariant"
      :switch="control.switch"
      :state="controlState"
      :autofocus="control.autofocus"
      @input="onControlInput"
      @change="onControlChange"
    >
      <span v-if="control.text || $slots.text" class="control-text">
        <slot name="text"><span v-html="control.text"/></slot>
      </span>
    </b-form-checkbox>

    <b-form-valid-feedback v-if="control.feedbacksValid" :state="controlState">{{ control.feedbacksValid }}</b-form-valid-feedback>
    <b-form-invalid-feedback :state="controlState">{{ controlInvalidFeedbacks }}</b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { guid } from '@/assets/js/helper/guid'

export default {
  name: 'ControlCheckbox',
  props: {
    id: { type: String, default: null },
    name: { type: String, default: '' },
    form: { type: String, default: '' },
    formGroupClass: { type: [String, Object, Array], default: '' },
    controlClass: { type: [String, Object, Array], default: '' },
    label: { type: String, default: '' },
    labelSrOnly: { type: Boolean, default: false },
    labelCols: { type: [Number, String, Boolean], default: null },
    labelColsSM: { type: [Number, String, Boolean], default: null },
    labelColsMD: { type: [Number, String, Boolean], default: null },
    labelColsLG: { type: [Number, String, Boolean], default: null },
    labelColsXL: { type: [Number, String, Boolean], default: null },
    labelAlign: { type: String, default: '' },
    labelAlignSM: { type: String, default: '' },
    labelAlignMD: { type: String, default: '' },
    labelAlignLG: { type: String, default: '' },
    labelAlignXL: { type: String, default: '' },
    value: { type: [String, Number, Boolean, Array, Object], default: () => ([]) },
    description: { type: String, default: '' },
    text: { type: String, default: '' },
    options: { type: [Object, Array], default: null },
    size: { type: String, default: '' },
    dirty: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    plain: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    stacked: { type: Boolean, default: false },
    button: { type: Boolean, default: false },
    buttonVariant: { type: String, default: '' },
    switch: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    validations: { type: Object, default: () => ({}) },
    feedbacksValid: { type: String, default: '' },
    feedbacksInvalid: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      control: {
        id: this.$props.id || guid(),
        name: this.$props.name,
        form: this.$props.form,
        formGroupClass: this.$props.formGroupClass,
        controlClass: this.$props.controlClass,
        label: this.$props.label,
        labelSrOnly: this.$props.labelSrOnly,
        labelCols: this.$props.labelCols,
        labelColsSM: this.$props.labelColsSM,
        labelColsMD: this.$props.labelColsMD,
        labelColsLG: this.$props.labelColsLG,
        labelColsXL: this.$props.labelColsXL,
        labelAlign: this.$props.labelAlign,
        labelAlignSM: this.$props.labelAlignSM,
        labelAlignMD: this.$props.labelAlignMD,
        labelAlignLG: this.$props.labelAlignLG,
        labelAlignXL: this.$props.labelAlignXL,
        value: this.$props.value,
        description: this.$props.description,
        text: this.$props.text,
        options: this.$props.options,
        size: this.$props.size,
        dirty: this.$props.dirty,
        state: this.$props.state,
        plain: this.$props.plain,
        disabled: this.$props.disabled,
        stacked: this.$props.stacked,
        button: this.$props.button,
        buttonVariant: this.$props.buttonVariant,
        switch: this.$props.switch,
        autofocus: this.$props.autofocus,
        validations: this.$props.validations,
        feedbacksValid: this.$props.feedbacksValid,
        feedbacksInvalid: this.$props.feedbacksInvalid
      }
    }
  },
  validations () {
    return {
      control: {
        value: this.control.validations
      }
    }
  },
  computed: {
    validator () {
      return this.$v.control.value
    },
    controlCanValidate () {
      if (this.validator !== undefined) return this.validator.$model !== undefined ? Object.keys(this.validator.$params).length > 0 : false
      return false
    },
    controlState () {
      if (this.controlCanValidate) return this.validator.$dirty ? !this.validator.$error : null
      if (this.control.state !== null) return this.control.dirty ? this.control.state : null
      return this.control.dirty ? true : null
    },
    controlInvalidFeedbacks () {
      if (this.controlCanValidate) {
        const validations = Object.keys(this.validator.$params || {})
        const feedbacks = this.feedbacksInvalid || {}
        const error = validations.find(key => this.validator[key] === false) || ''

        return this.validator.$error ? feedbacks[error] instanceof Function ? feedbacks[error]() : feedbacks[error] || '' : ''
      }

      if (this.control.dirty && !this.control.state) {
        return this.feedbacksInvalid.error || ''
      }

      return ''
    },
    formGroupClasses () {
      return [
        {
          'is-plain': this.control.plain,
          'is-disabled': this.control.disabled,
          'is-button': this.control.button,
          'is-stacked': this.control.stacked,
          'is-switch': this.control.switch,
          'is-dirty': this.controlCanValidate ? this.validator.$dirty : false
        }
      ].concat(this.control.formGroupClass || [])
    },
    controlClasses () {
      return [].concat(this.control.controlClass || [])
    }
  },
  methods: {
    setDirty (dirty) {
      if (this.controlCanValidate) {
        if (dirty) {
          this.validator.$touch()
        } else {
          this.validator.$reset()
        }
      }
    },
    onControlInput (controlValue) {
      if (this.controlCanValidate && this.validator.$dirty) {
        this.validator.$model = controlValue
        this.validator.$touch()
      }

      this.control.state = null

      this.$emit('input', controlValue)
    },
    onControlChange (controlValue) {
      if (this.controlCanValidate) {
        this.validator.$model = controlValue
        this.validator.$touch()
      }

      this.control.state = null

      this.$emit('change', controlValue)
    }
  },
  created () {
    this.setDirty(this.control.dirty)
  },
  watch: {
    '$props.id' (id) { this.control.id = id },
    '$props.name' (name) { this.control.name = name },
    '$props.form' (form) { this.control.form = form },
    '$props.formGroupClass' (formGroupClass) { this.control.formGroupClass = formGroupClass },
    '$props.controlClass' (controlClass) { this.control.controlClass = controlClass },
    '$props.label' (label) { this.control.label = label },
    '$props.labelSrOnly' (labelSrOnly) { this.control.labelSrOnly = labelSrOnly },
    '$props.labelCols' (labelCols) { this.control.labelCols = labelCols },
    '$props.labelColsSM' (labelColsSM) { this.control.labelColsSM = labelColsSM },
    '$props.labelColsMD' (labelColsMD) { this.control.labelColsMD = labelColsMD },
    '$props.labelColsLG' (labelColsLG) { this.control.labelColsLG = labelColsLG },
    '$props.labelColsXL' (labelColsXL) { this.control.labelColsXL = labelColsXL },
    '$props.labelAlign' (labelAlign) { this.control.labelAlign = labelAlign },
    '$props.labelAlignSM' (labelAlignSM) { this.control.labelAlignSM = labelAlignSM },
    '$props.labelAlignMD' (labelAlignMD) { this.control.labelAlignMD = labelAlignMD },
    '$props.labelAlignLG' (labelAlignLG) { this.control.labelAlignLG = labelAlignLG },
    '$props.labelAlignXL' (labelAlignXL) { this.control.labelAlignXL = labelAlignXL },
    '$props.value' (value) { this.control.value = value },
    '$props.description' (description) { this.control.description = description },
    '$props.text' (text) { this.control.text = text },
    '$props.options' (options) { this.control.options = options },
    '$props.size' (size) { this.control.size = size },
    '$props.dirty' (dirty) {
      this.control.dirty = dirty
      this.setDirty(this.control.dirty)
    },
    '$props.state' (state) { this.control.state = state },
    '$props.plain' (plain) { this.control.plain = plain },
    '$props.disabled' (disabled) { this.control.disabled = disabled },
    '$props.stacked' (stacked) { this.control.stacked = stacked },
    '$props.button' (button) { this.control.button = button },
    '$props.buttonVariant' (buttonVariant) { this.control.buttonVariant = buttonVariant },
    '$props.switch' (isSwitch) { this.control.switch = isSwitch },
    '$props.autofocus' (autofocus) { this.control.autofocus = autofocus },
    '$props.feedbacksValid' (feedbacksValid) { this.control.feedbacksValid = feedbacksValid },
    '$props.feedbacksInvalid' (feedbacksInvalid) { this.control.feedbacksInvalid = feedbacksInvalid }
  }
}
</script>

<style lang="scss">
.control-checkbox {}
</style>
