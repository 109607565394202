<template>
  <div :class="['summary-price', { 'is-total': total }]" role="presentation">
    <dt class="text" v-html="text"></dt>

    <i18n-n
      tag="dd"
      :value="price.number"
      :format="{ key: 'currency', currency }"
      class="price"
    >
      <template #currency="slotProps"><span :class="['currency', { 'd-none': !showCurrency }]">{{ slotProps.currency }}</span></template>
      <template #integer="slotProps"><span class="integer">{{ slotProps.integer }}</span></template>
      <template #group="slotProps"><span class="group">{{ slotProps.group }}</span></template>
      <template #decimal="slotProps"><span class="decimal">{{ slotProps.decimal }}</span></template>
      <template #fraction="slotProps"><span class="fraction">{{ slotProps.fraction }}</span></template>
    </i18n-n>
  </div>
</template>

<script>
export default {
  name: 'SummaryPrice',
  props: {
    text: {
      type: String,
      default: ''
    },
    amount: {
      type: [String, Number],
      default: 0
    },
    currency: {
      type: String,
      default: 'CHF'
    },
    total: {
      type: Boolean,
      default: false
    },
    showCurrency: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    price () {
      return this.createPrice(this.amount)
    }
  },
  methods: {
    createPrice (value) {
      const number = Number(value)

      return {
        isValid: !isNaN(number) && number > 0,
        original: value,
        number
      }
    }
  }
}
</script>

<style lang="scss">
$summaryprice-total-margin-y: $spacer * 0.5 !default;
$summaryprice-total-border: set-nth(map-get($borders, 'bold'), 3, $hr-border-color) !default;
$summaryprice-total-font-weight: $font-weight-bold !default;

.summary-price {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  dt,
  dd {
    margin: 0;
    font-weight: inherit;
  }

  &.is-total {
    margin-top: $summaryprice-total-margin-y;
    padding-top: $summaryprice-total-margin-y;
    border-top: $summaryprice-total-border;
    font-weight: $summaryprice-total-font-weight;
  }
}
</style>
