<template>
  <controls-form
    :id="id"
    :isBusy="isBusy"
    :controlDefinition="definition"
    :controlValues="entityValues"
    :triggerInit="triggerInit"
    :showButtons="showButtons"
    :translations="$props.translations"
    :translationsOnce="$props.translationsOnce"
    :translationsPath="$props.translationsPath"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #abort><slot name="abort"/></template>
    <template #reset><slot name="reset"/></template>
    <template #submit><slot name="submit"/></template>
  </controls-form>
</template>

<script>
import ControlsForm from '@/components/private/forms/ControlsForm'

export default {
  name: 'EntityForm',
  components: {
    ControlsForm
  },
  props: {
    id: {
      type: String,
      required: true
    },
    entityKey: {
      type: String,
      default: null
    },
    entityDefinition: {
      type: Object,
      default: () => ({})
    },
    entityValues: {
      type: Object,
      default: () => ({})
    },
    definitionModifier: {
      type: Function,
      default: null
    },
    triggerInit: {
      type: Boolean,
      default: false
    },
    showButtons: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      busyStates: {
        validators: true,
        entityDefinition: true
      }
    }
  },
  computed: {
    isBusy () {
      return Object.values(this.busyStates).some(s => s === true)
    },
    validatorsIs () {
      return this.$store.getters['validators/is']
    },
    validators () {
      return this.$store.getters['validators/get']
    },
    dynamicEntityDefinition () {
      return this.entityKey ? this.$store.getters[`${this.entityKey}/definition`] : null
    },
    composedDefinition () {
      return Object.assign({}, this.entityDefinition, this.dynamicEntityDefinition, {
        properties: this.entityDefinition.properties || this.dynamicEntityDefinition.properties || []
      })
    },
    definition () {
      return typeof this.definitionModifier === 'function' ? this.definitionModifier(this.composedDefinition) : this.composedDefinition
    }
  },
  created () {
    this.$store.dispatch('validators/get').finally(() => { this.busyStates.validators = false })

    if (this.entityKey && !this.dynamicEntityDefinition.id) {
      this.$store.dispatch(`${this.entityKey}/getDefinition`).finally(() => { this.busyStates.entityDefinition = false })
    } else {
      this.busyStates.entityDefinition = false
    }
  },
  watch: {
    '$props.controlDefinition': {
      deep: true,
      handler (definition) {
        this.entityDefinition = definition
      }
    },
    '$props.controlValues': {
      deep: true,
      handler (values) {
        this.entityValues = values
      }
    }
  }
}
</script>

<style lang="scss"></style>
