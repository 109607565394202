<template>
  <b-form-group
    class="control-input"
    :label="control.label"
    :label-for="control.id"
    :label-sr-only="control.labelSrOnly"
    :label-cols="control.labelCols"
    :label-cols-sm="control.labelColsSM"
    :label-cols-md="control.labelColsMD"
    :label-cols-lg="control.labelColsLG"
    :label-cols-xl="control.labelColsXL"
    :label-align="control.labelAlign"
    :label-align-sm="control.labelAlignSM"
    :label-align-md="control.labelAlignMD"
    :label-align-lg="control.labelAlignLG"
    :label-align-xl="control.labelAlignXL"
    :class="formGroupClasses"
    :description="control.description"
    :state="controlState"
  >
    <div class="position-relative" v-on:submit.prevent>
      <autocomplete
        :search="searchMethod"
        :get-result-value="formatResultValue"
        :class="inputGroupClasses"
        :submitOnEnter="true"
        :auto-select="true"
        :default-value="getDefaultValue()"
        @submit="selectResult"
        @blur="onInputBlur"
        ref="autocomplete"
      >
      </autocomplete>
    </div>

    <b-form-valid-feedback v-if="control.feedbacksValid" :state="controlState">
      {{ control.feedbacksValid }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="controlState">
      {{ controlInvalidFeedbacks }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { guid } from '@/assets/js/helper/guid'
import { format } from '@/assets/js/helper/string'
import Autocomplete from '@trevoreyre/autocomplete-vue'

import http from '@/$plugins/http'

export default {
  name: 'ControlAutocomplete',
  components: {
    Autocomplete
  },
  props: {
    id: { type: String, default: null },
    name: { type: String, default: '' },
    form: { type: String, default: '' },
    type: { type: String, default: 'text' },
    formGroupClass: { type: [String, Object, Array], default: '' },
    inputGroupClass: { type: [String, Object, Array], default: '' },
    controlClass: { type: [String, Object, Array], default: '' },
    tabindex: { type: [String, Number], default: null },
    label: { type: String, default: '' },
    labelSrOnly: { type: Boolean, default: false },
    labelCols: { type: [Number, String, Boolean], default: null },
    labelColsSM: { type: [Number, String, Boolean], default: null },
    labelColsMD: { type: [Number, String, Boolean], default: null },
    labelColsLG: { type: [Number, String, Boolean], default: null },
    labelColsXL: { type: [Number, String, Boolean], default: null },
    labelAlign: { type: String, default: '' },
    labelAlignSM: { type: String, default: '' },
    labelAlignMD: { type: String, default: '' },
    labelAlignLG: { type: String, default: '' },
    labelAlignXL: { type: String, default: '' },
    value: { type: [String, Number, Array, Object], default: null },
    placeholder: { type: String, default: '' },
    description: { type: String, default: '' },
    configuration: { type: Object, default: () => ({}) },
    size: { type: String, default: '' },
    dirty: { type: Boolean, default: false },
    state: { type: Boolean, default: null },
    readonly: { type: Boolean, default: false },
    plaintext: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    autofocus: { type: Boolean, default: false },
    autocomplete: { type: String, default: null },
    pattern: { type: String, default: null },
    formatter: { type: Function, default: undefined },
    lazyFormatter: { type: Boolean, default: false },
    validations: { type: Object, default: () => ({}) },
    feedbacksValid: { type: String, default: '' },
    feedbacksInvalid: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      control: {
        id: this.$props.id || guid(),
        name: this.$props.name,
        form: this.$props.form,
        type: this.$props.type,
        formGroupClass: this.$props.formGroupClass,
        inputGroupClass: this.$props.inputGroupClass,
        controlClass: this.$props.controlClass,
        tabindex: this.$props.tabindex,
        label: this.$props.label,
        labelSrOnly: this.$props.labelSrOnly,
        labelCols: this.$props.labelCols,
        labelColsSM: this.$props.labelColsSM,
        labelColsMD: this.$props.labelColsMD,
        labelColsLG: this.$props.labelColsLG,
        labelColsXL: this.$props.labelColsXL,
        labelAlign: this.$props.labelAlign,
        labelAlignSM: this.$props.labelAlignSM,
        labelAlignMD: this.$props.labelAlignMD,
        labelAlignLG: this.$props.labelAlignLG,
        labelAlignXL: this.$props.labelAlignXL,
        value: this.$props.value,
        placeholder: this.$props.placeholder,
        description: this.$props.description,
        configuration: this.$props.configuration,
        size: this.$props.size,
        dirty: this.$props.dirty,
        state: this.$props.state,
        readonly: this.$props.readonly,
        plaintext: this.$props.plaintext,
        disabled: this.$props.disabled,
        autofocus: this.$props.autofocus,
        autocomplete: this.$props.autocomplete,
        pattern: this.$props.pattern,
        formatter: this.$props.formatter,
        lazyFormatter: this.$props.lazyFormatter,
        validations: this.$props.validations,
        feedbacksValid: this.$props.feedbacksValid,
        feedbacksInvalid: this.$props.feedbacksInvalid,
        hasFocus: false
      }
    }
  },
  validations () {
    return {
      control: {
        value: this.control.validations
      }
    }
  },
  computed: {
    validator () {
      return this.$v.control.value
    },
    controlCanValidate () {
      if (this.validator !== undefined) return this.validator.$model !== undefined ? Object.keys(this.validator.$params).length > 0 : false
      return false
    },
    controlState () {
      if (this.controlCanValidate) return this.validator.$dirty ? !this.validator.$error : null
      if (this.control.state !== null) return this.control.dirty ? this.control.state : null
      return this.control.dirty ? true : null
    },
    controlInvalidFeedbacks () {
      if (this.controlCanValidate) {
        const validations = Object.keys(this.validator.$params || {})
        const feedbacks = this.feedbacksInvalid || {}
        const error = validations.find(key => this.validator[key] === false) || ''

        return this.validator.$error ? feedbacks[error] instanceof Function ? feedbacks[error]() : feedbacks[error] || '' : ''
      }

      if (this.control.dirty && !this.control.state) {
        return this.feedbacksInvalid.error || ''
      }

      return ''
    },
    formGroupClasses () {
      return [
        {
          'has-focus': this.control.hasFocus,
          'has-prepend': this.$slots.prepend,
          'has-append': this.$slots.append,
          'is-filled': this.control.value !== undefined && this.control.value !== null && this.control.value !== '',
          'is-readonly': this.control.readonly,
          'is-plaintext': this.control.plaintext,
          'is-disabled': this.control.disabled,
          'is-dirty': this.controlCanValidate ? this.validator.$dirty : false
        }
      ].concat(this.control.formGroupClass || [])
    },
    inputGroupClasses () {
      return [
        {
          'mb-0': this.control.label === ''
        }
      ].concat(this.control.formInputClass || [])
    },
    controlClasses () {
      return [
        {
          'd-none': this.control.type === 'hidden'
        }
      ].concat(this.control.controlClass || [])
    }
  },
  methods: {
    setDirty (dirty) {
      if (this.controlCanValidate) {
        if (dirty) {
          this.validator.$touch()
        } else {
          this.validator.$reset()
        }
      }
    },
    searchMethod(query) {
      return new Promise((resolve, reject) => {
        if (query.length < 2) {
          return resolve([])
        }

        http({
          method: 'get',
          url: `/customer/api/AutoComplete/${this.configuration.entityId}/${this.name}/${query}`
        })
          .then(response => {
            if (response.data && response.data.success) {
              resolve(response.data.result)
            }
          })
          .catch(reject)
          .finally(() => {
          })
      })
    },
    getDefaultValue() {
      // returns the control.value when it is a string, otherwise formats the control.value
      return typeof this.control.value === 'string' ? this.control.value : format(this.configuration.relatedEntityLabelFormat, this.control.value)
    },
    formatResultValue(result) {
      return format(this.configuration.relatedEntityLabelFormat, result)
    },
    selectResult(result) {
      this.control.value = result
      // Emit an event or perform other actions after selection
      this.$emit('change', this.control.value)
    },
    inputChanged () {
      if (this.controlCanValidate && this.validator.$dirty) {
        this.validator.$model = this.control.value
        this.validator.$touch()
      }

      this.control.state = null

      if (this.control.value !== null) {
        this.control.value = null
        this.$emit('input', this.control.value)
      }
    },
    onInputBlur () {
      if (this.controlCanValidate) {
        this.validator.$model = this.control.value
        this.validator.$touch()
      }

      this.control.state = null

      this.$emit('change', this.control.value)
    },
    setTabindex () {
      const input = this.$el.querySelector('input')
      if (input) input.setAttribute('tabindex', this.control.tabindex)
    }
  },
  created () {
    this.setDirty(this.control.dirty)
  },
  mounted () {
    this.$nextTick(() => {
      this.setTabindex()

      this.$el.querySelector('input').addEventListener('input', (event) => {
        this.inputChanged(event)
      })
    })
  },
  watch: {
    '$props.id' (id) { this.control.id = id },
    '$props.name' (name) { this.control.name = name },
    '$props.form' (form) { this.control.form = form },
    '$props.type' (type) { this.control.type = type },
    '$props.formGroupClass' (formGroupClass) { this.control.formGroupClass = formGroupClass },
    '$props.inputGroupClass' (inputGroupClass) { this.control.inputGroupClass = inputGroupClass },
    '$props.controlClass' (controlClass) { this.control.controlClass = controlClass },
    '$props.tabindex' (tabindex) { this.control.tabindex = tabindex },
    '$props.label' (label) { this.control.label = label },
    '$props.labelSrOnly' (labelSrOnly) { this.control.labelSrOnly = labelSrOnly },
    '$props.labelCols' (labelCols) { this.control.labelCols = labelCols },
    '$props.labelColsSM' (labelColsSM) { this.control.labelColsSM = labelColsSM },
    '$props.labelColsMD' (labelColsMD) { this.control.labelColsMD = labelColsMD },
    '$props.labelColsLG' (labelColsLG) { this.control.labelColsLG = labelColsLG },
    '$props.labelColsXL' (labelColsXL) { this.control.labelColsXL = labelColsXL },
    '$props.labelAlign' (labelAlign) { this.control.labelAlign = labelAlign },
    '$props.labelAlignSM' (labelAlignSM) { this.control.labelAlignSM = labelAlignSM },
    '$props.labelAlignMD' (labelAlignMD) { this.control.labelAlignMD = labelAlignMD },
    '$props.labelAlignLG' (labelAlignLG) { this.control.labelAlignLG = labelAlignLG },
    '$props.labelAlignXL' (labelAlignXL) { this.control.labelAlignXL = labelAlignXL },
    '$props.value' (value) { this.control.value = value },
    '$props.placeholder' (placeholder) { this.control.placeholder = placeholder },
    '$props.description' (description) { this.control.description = description },
    '$props.configuration' (configuration) { this.control.configuration = configuration },
    '$props.size' (size) { this.control.size = size },
    '$props.dirty' (dirty) {
      this.control.dirty = dirty
      this.setDirty(this.control.dirty)
    },
    '$props.state' (state) { this.control.state = state },
    '$props.readonly' (readonly) { this.control.readonly = readonly },
    '$props.plaintext' (plaintext) { this.control.plaintext = plaintext },
    '$props.disabled' (disabled) { this.control.disabled = disabled },
    '$props.autofocus' (autofocus) { this.control.autofocus = autofocus },
    '$props.autocomplete' (autocomplete) { this.control.autocomplete = autocomplete },
    '$props.pattern' (pattern) { this.control.pattern = pattern },
    '$props.formatter' (formatter) { this.control.formatter = formatter },
    '$props.lazyFormatter' (lazyFormatter) { this.control.lazyFormatter = lazyFormatter },
    '$props.validations' (validations) { this.control.validations = validations },
    '$props.feedbacksValid' (feedbacksValid) { this.control.feedbacksValid = feedbacksValid },
    '$props.feedbacksInvalid' (feedbacksInvalid) { this.control.feedbacksInvalid = feedbacksInvalid },
    'control.tabindex' (tabindex) { this.setTabindex() }
  }
}
</script>

<style lang="scss">
@import '~@trevoreyre/autocomplete-vue/dist/style.css';

.autocomplete {
  .autocomplete-input {
    max-width: initial !important;

    font-size: 1rem;
    line-height: 1.5;
    border-radius: 3px;
    height: calc(1.5em + 0.876rem + 4px);
    font-weight: 400;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.54);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      box-shadow: none;
    }
  }

  .autocomplete-result-list {
    border: 1px solid rgba(0, 0, 0, 0.54);

    .autocomplete-result {
      background-image: none;
    }
  }

  &[data-position=above] {
    .autocomplete-input[aria-expanded=true] {
      border-radius: 0 0 3px 3px;
    }

    .autocomplete-result-list {
      border-bottom-color: transparent;
      border-radius: 3px 3px 0 0;
    }
  }

  &[data-position=below] {
    .autocomplete-input[aria-expanded=true] {
      border-radius: 3px 3px 0 0;
    }

    .autocomplete-result-list {
      border-top-color: transparent;
      border-radius: 0 0 3px 3px;
    }
  }
}
</style>
