<template>
  <div class="order-configuration">
    <template v-if="summary">
      <h2 class="summary-title">{{ $t(`${tPath}.summary.title`) }}</h2>

      <dl class="summary-list">
        <component v-for="(cost, cIndex) in summary" :key="cIndex" :is="cost.component" :text="cost.label" :amount="cost.price" v-bind="cost.attrs" />
      </dl>
    </template>

    <template v-if="isStoreClerk && configuration.isStoreClerk">
      <h2 class="configuration-title">{{ $t(`${tPath}.user.title`) }}</h2>

      <dl class="configuration-list">
        <template v-if="!configuration.isUserUpdate">
          <dt>{{ $t(`${tPath}.user.newcustomer`) }}</dt>
        </template>
        <template v-else>
          <dt>{{ $t(`${tPath}.user.existingcustomer`) }}</dt>
        </template>
        <dd>
          <template v-if="configuration.user.company && configuration.user.company.length > 0">{{ configuration.user.company }}<br /></template>
          {{ configuration.user.firstname }} {{ configuration.user.lastname }}<br />
          {{ configuration.user.email }}<br /><br />
          <template v-if="configuration.userAddress.company && configuration.userAddress.company.length > 0">{{ configuration.userAddress.company }}<br /></template>
          {{ configuration.userAddress.street }}<template v-if="configuration.userAddress.addition && configuration.userAddress.addition.length > 0"> {{ configuration.userAddress.addition }}</template><br />
          {{ configuration.userAddress.zip }} {{ configuration.userAddress.city }}
        </dd>

        <dt>{{ $t(`${tPath}.user.createlogin.title`) }}</dt>
        <dd v-if="configuration.userLoginExists === true">{{ $t(`${tPath}.user.createlogin.existing`) }}</dd>
        <dd v-else>{{ $t(`${tPath}.user.createlogin.${configuration.createUserLogin}`) }}</dd>

        <template v-if="$root.isBoolean(configuration.isGastro) && configuration.isGastro">
          <dt>{{ $t(`${tPath}.user.isgastro.label`) }}</dt>
          <dd>{{ $t(`${tPath}.user.isgastro.${configuration.isGastro}`) }}</dd>
        </template>

        <dt>{{ $t(`${tPath}.storeclerk.primarysellername`) }}</dt>
        <dd><a href="mailto:verkauf@hunn.ch">{{ configuration.primarySellerName }} (verkauf@hunn.ch)</a></dd>

        <template v-if="!$root.isNullOrEmpty(configuration.additionalSellerName)">
          <dt>{{ $t(`${tPath}.storeclerk.additionalsellername`) }}</dt>
          <dd>{{ configuration.additionalSellerName }}</dd>
        </template>

        <dt>{{ $t(`${tPath}.storeclerk.campaign`) }}</dt>
        <dd>{{ configuration.campaignName }}</dd>
      </dl>
    </template>

    <template v-else-if="configuration.isStoreClerk">
      <h2 class="configuration-title">{{ $t(`${tPath}.storeclerk.title`) }}</h2>

      <dl class="configuration-list">
        <dt>{{ $t(`${tPath}.storeclerk.primarysellername`) }}</dt>
        <dd><a href="mailto:verkauf@hunn.ch">{{ configuration.primarySellerName }} (verkauf@hunn.ch)</a></dd>

        <template v-if="!$root.isNullOrEmpty(configuration.additionalSellerName)">
          <dt>{{ $t(`${tPath}.storeclerk.additionalsellername`) }}</dt>
          <dd>{{ configuration.additionalSellerName }}</dd>
        </template>

        <dt>{{ $t(`${tPath}.storeclerk.campaign`) }}</dt>
        <dd>{{ configuration.campaignName }}</dd>
      </dl>
    </template>

    <h2 class="configuration-title">{{ $t(`${tPath}.delivery.title`) }}</h2>

    <dl class="configuration-list">
      <template v-if="configuration.deliveryType">
        <dt>{{ $t(`${tPath}.delivery.type.title`) }}</dt>
        <dd v-if="configuration.deliveryType === 'Delivery' && isReseller">{{ $t(`${tPath}.delivery.reseller.title`) }}</dd>
        <dd v-else>{{ $t(`${tPath}.delivery.type.${configuration.deliveryType.toLowerCase()}`) }}</dd>
      </template>

      <template v-if="configuration.commissionText">
        <dt>{{ $t(`${tPath}.delivery.commissiontext.title`) }}</dt>
        <dd>{{ configuration.commissionText }}</dd>
      </template>

      <template v-if="configuration.digitalDownloadWithEnvelopDelivery !== undefined && configuration.digitalDownloadWithEnvelopDelivery !== null">
        <dt>{{ $t(`${tPath}.delivery.digitaldownloadwithenvelopdelivery.title`) }}</dt>
        <dd>{{ $t(`${tPath}.delivery.digitaldownloadwithenvelopdelivery.${configuration.digitalDownloadWithEnvelopDelivery}`) }}</dd>
      </template>

      <!-- Lieferung -->
      <template v-if="configuration.deliveryType === 'Delivery'">
        <template v-if="isReseller">
          <dt></dt>
          <dd v-html="$t(`${tPath}.delivery.reseller.description`)"></dd>
        </template>

        <template v-else>
          <template v-if="configuration.deliveryCostBilling">
            <dt>{{ $t(`${tPath}.delivery.costbilling.title`) }}</dt>
            <dd>{{ $t(`${tPath}.delivery.costbilling.${configuration.deliveryCostBilling.toLowerCase()}`) }}</dd>
          </template>

          <template v-if="configuration.deliveryFloorType">
            <dt>{{ $t(`${tPath}.delivery.floor.title`) }}</dt>
            <dd>{{ $t(`${tPath}.delivery.floor.${configuration.deliveryFloorType.toLowerCase()}`) }}</dd>
          </template>

          <template v-if="$root.isBoolean(configuration.deliveryElevatorAvailable)">
            <dt>{{ $t(`${tPath}.delivery.elevatoravailable.title`) }}</dt>
            <dd>{{ $t(`${tPath}.delivery.elevatoravailable.${configuration.deliveryElevatorAvailable}`) }}</dd>
          </template>

          <template v-if="configuration.deliveryComments">
            <dt>{{ $t(`${tPath}.delivery.comments.title`) }}</dt>
            <dd>{{ configuration.deliveryComments }}</dd>
          </template>

          <template v-if="$root.isBoolean(configuration.discardOldFurniture)">
            <dt>{{ $t(`${tPath}.delivery.discardoldfurniture.title`) }}</dt>
            <dd>{{ $t(`${tPath}.delivery.discardoldfurniture.${configuration.discardOldFurniture}`) }}</dd>
          </template>

          <template v-if="configuration.discardOldFurnitureComment">
            <dt>{{ $t(`${tPath}.delivery.discardoldfurniturecomment.title`) }}</dt>
            <dd>{{ configuration.discardOldFurnitureComment }}</dd>
          </template>

          <template v-if="configuration.deliveryAddress">
            <dt>{{ $t(`${tPath}.delivery.deliveryaddress.title`) }}</dt>
            <dd><c-checkout-user-address :predefined="{ user: configuration.user, address: configuration.deliveryAddress }" /></dd>
          </template>

          <template v-if="isMithilfeAblad">
            <dt>{{ $t(`${tPath}.delivery.mithilfeablad.title`) }}</dt>
            <dd>{{ $t(`${tPath}.delivery.mithilfeablad.description`) }}</dd>
          </template>
        </template>
      </template>

      <!-- Abholung -->
      <template v-else-if="configuration.deliveryType === 'Pickup' || configuration.deliveryType === 'PickupLyssach'">
        <template v-if="configuration.pickupTime || configuration.SetPickupTimeLater || configuration.pickupDate">
          <dt>{{ $t(`${tPath}.delivery.pickupdate.title`) }}</dt>
          <dd>
            <template v-if="configuration.pickupTime === 'ByDate'">{{ $d(new Date(configuration.pickupDate), 'dateDetailed') }}</template>
            <!-- legacy code for old orders -->
            <template v-else-if="configuration.pickupDate && configuration.SetPickupTimeLater !== undefined">{{ $d(new Date(configuration.pickupDate)) }}</template>
            <template v-else-if="configuration.SetPickupTimeLater">
              {{ $t(`${tPath}.delivery.pickupdate.selectlater`) }}
              <div class="text-muted">{{ $t(`${tPath}.delivery.pickupdate.selectlaterinfo`) }}</div>
            </template>
            <template v-else>
              {{ $t(`${tPath}.delivery.pickupdate.${configuration.pickupTime.toLowerCase()}.title`) }}
              <div class="text-muted">{{ $t(`${tPath}.delivery.pickupdate.${configuration.pickupTime.toLowerCase()}.explanation`) }}</div>
            </template>
          </dd>
        </template>

        <template v-if="configuration.pickupComments">
          <dt>{{ $t(`${tPath}.delivery.pickupcomments.title`) }}</dt>
          <dd>{{ configuration.pickupComments }}</dd>
        </template>

        <template v-if="!isReseller">
          <template v-if="$root.isBoolean(configuration.rentHunnVan)">
            <dt>{{ $t(`${tPath}.delivery.renthunnvan.title`) }}</dt>
            <dd>
              {{ $t(`${tPath}.delivery.renthunnvan.${configuration.rentHunnVan}`) }}
              <template v-if="configuration.rentHunnVan && $root.isBoolean(configuration.rentHunnVanForFree) && configuration.rentHunnVanForFree">
                {{ $t(`${tPath}.delivery.renthunnvan.forfree`) }}
              </template>
            </dd>
          </template>

          <template v-if="$root.isBoolean(configuration.partialDelivery)">
            <dt>{{ $t(`${tPath}.delivery.partialdelivery.title`) }}</dt>
            <dd>{{ $t(`${tPath}.delivery.partialdelivery.${configuration.partialDelivery}`) }}</dd>
          </template>
        </template>
      </template>
    </dl>

    <h2 class="configuration-title">{{ $t(`${tPath}.payment.title`) }}</h2>

    <dl class="configuration-list">
      <template v-if="!$root.isNullOrEmpty(configuration.paymentType)">
        <dt>{{ $t(`${tPath}.payment.type.title`) }}</dt>
        <dd>{{ $t(`${tPath}.payment.type.${configuration.paymentType.toLowerCase()}`) }}</dd>
      </template>

      <template v-if="(redeemedCoupons || []).length">
        <dt>{{ $t(`${tPath}.payment.coupons.title`) }}</dt>
        <dd>
          <div v-for="coupon in redeemedCoupons" :key="coupon.id">
            <strong>{{ coupon.code }}</strong> {{ coupon.name }}
            <span class="small" v-if="coupon.balance">
              ({{ $t(`${tPath}.payment.coupons.balance`) }}
              <i18n-n tag="span" :value="coupon.balance" :format="{ key: 'currency', currency: coupon.currency }" class="price">
                <template #currency="slotProps"><span :class="['currency']">{{ slotProps.currency }}</span></template>
                <template #integer="slotProps"><span class="integer">{{ slotProps.integer }}</span></template>
                <template #group="slotProps"><span class="group">{{ slotProps.group }}</span></template>
                <template #decimal="slotProps"><span class="decimal">{{ slotProps.decimal }}</span></template>
                <template #fraction="slotProps"><span class="fraction">{{ slotProps.fraction }}</span></template>
              </i18n-n>)
            </span>
          </div>
        </dd>
      </template>

      <template v-if="(usedCoupons || []).length">
        <dt>{{ $t(`${tPath}.payment.coupons.title`) }}</dt>
        <dd>
          <div v-for="coupon in usedCoupons" :key="coupon.id">
            <strong>{{ coupon.code }}</strong> {{ coupon.name }}
            <br v-if="coupon.usedAmount" />
            <span class="small" v-if="coupon.usedAmount">
              ({{ $t(`${tPath}.payment.coupons.usedamount`) }}
              <i18n-n tag="span" :value="coupon.usedAmount" :format="{ key: 'currency', currency: coupon.currency }" class="price">
                <template #currency="slotProps"><span :class="['currency']">{{ slotProps.currency }}</span></template>
                <template #integer="slotProps"><span class="integer">{{ slotProps.integer }}</span></template>
                <template #group="slotProps"><span class="group">{{ slotProps.group }}</span></template>
                <template #decimal="slotProps"><span class="decimal">{{ slotProps.decimal }}</span></template>
                <template #fraction="slotProps"><span class="fraction">{{ slotProps.fraction }}</span></template>
              </i18n-n>)
            </span>
          </div>
        </dd>
      </template>

      <template>
        <dt>{{ $t(`${tPath}.payment.invoiceaddress.title`) }}</dt>
        <dd>
          <c-checkout-user-address v-if="!$root.isNullOrEmpty(configuration.invoiceAddress)" :predefined="{ user: configuration.user, address: configuration.invoiceAddress }" />
          <c-checkout-user-address v-else-if="configuration.invoiceAddressIsDeliveryAddress" :predefined="{ user: configuration.user, address: configuration.deliveryAddress }" />
        </dd>
      </template>
      <template v-if="!$root.isNullOrEmpty(configuration.storeClerkPaymentType)">
        <dt>{{ $t(`${tPath}.payment.storeclerkpaymenttype.title`) }}</dt>
        <dd>{{ $t(`${tPath}.payment.storeclerkpaymenttype.${configuration.storeClerkPaymentType.toLowerCase()}`) }}</dd>
      </template>
    </dl>

    <template v-if="!$root.isNullOrEmpty(configuration.customerSignature)">
      <dl class="configuration-list">
        <dt>{{ $t(`${tPath}.storeclerk.customersignature`) }}</dt>
        <dd><img :src="configuration.customerSignature" width="400" /></dd>
      </dl>
    </template>
  </div>
</template>

<script>
import SummaryPrice from '@/components/private/SummaryPrice';
import SummarySeparator from '@/components/private/SummarySeparator';

export default {
  name: 'OrderConfiguration',
  components: {
    SummaryPrice,
    SummarySeparator
  },
  props: {
    summary: {
      type: Array,
      default: null
    },
    configuration: {
      type: Object,
      required: true
    },
    redeemedCoupons: {
      type: Array,
      default: null
    },
    usedCoupons: {
      type: Array,
      default: null
    },
    isReseller: {
      type: Boolean,
      default: false
    },
    isStoreClerk: {
      type: Boolean,
      default: false
    },
    isMithilfeAblad: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$orderconfiguration-title-padding-y: $spacer * 0.85 !default;
$orderconfiguration-title-padding-x: $spacer * 1 !default;
$orderconfiguration-title-bg: $beige !default;
$orderconfiguration-title-font-size: $font-size-lg !default;
$orderconfiguration-title-font-weight: $font-weight-bold !default;
$orderconfiguration-title-color: inherit !default;

$orderconfiguration-list-gap: $spacer * 3 !default;
$orderconfiguration-list-padding-y: $spacer * 0.5 !default;
$orderconfiguration-list-padding-x: $orderconfiguration-title-padding-x !default;
$orderconfiguration-list-border: map-get($borders, 'base') !default;
$orderconfiguration-list-title-font-size: $font-size-sm !default;
$orderconfiguration-list-title-font-weight: inherit !default;
$orderconfiguration-list-title-color: $dark !default;
$orderconfiguration-list-data-font-size: inherit !default;
$orderconfiguration-list-data-font-weight: inherit !default;
$orderconfiguration-list-data-color: inherit !default;
$orderconfiguration-list-data-content-gap: $spacer * 0.3 !default;

.order-configuration {

  .summary-title,
  .configuration-title {
    margin: 0;
    padding: $orderconfiguration-title-padding-y $orderconfiguration-title-padding-x;
    background-color: $orderconfiguration-title-bg;
    font-size: $orderconfiguration-title-font-size;
    font-weight: $orderconfiguration-title-font-weight;
    color: $orderconfiguration-title-color;
  }

  .summary-list {
    margin-bottom: $orderconfiguration-list-gap;
    border-bottom: $orderconfiguration-list-border;

    > div {
      padding: 0 $orderconfiguration-list-padding-x;

      &.summary-separator {
        padding: $orderconfiguration-list-padding-y 0;

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-top: 0;
        }

        hr {
          margin: 0;
          border-color: nth($orderconfiguration-list-border, 3);
        }
      }

      &.summary-price {
        &.is-total {
          margin-top: $orderconfiguration-list-padding-y;
          padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
          border-top-color: nth($orderconfiguration-list-border, 3);
        }
      }
    }
  }

  .configuration-list {
    @include make-row(0);
    margin-bottom: $orderconfiguration-list-gap;
    border-top: $orderconfiguration-list-border;

    dt,
    dd {
      @include make-col-ready(0);
      margin: 0;
      padding: $orderconfiguration-list-padding-y $orderconfiguration-list-padding-x;
      border-bottom: $orderconfiguration-list-border;
    }

    dt {
      @include make-col(3);
      font-size: $orderconfiguration-list-title-font-size;
      font-weight: $orderconfiguration-list-title-font-weight;
      color: $orderconfiguration-list-title-color;
    }

    dd {
      @include make-col(9);
      font-size: $orderconfiguration-list-data-font-size;
      font-weight: $orderconfiguration-list-data-font-weight;
      color: $orderconfiguration-list-data-color;

      > * {
        margin-bottom: $orderconfiguration-list-data-content-gap;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
