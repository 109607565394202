<template>
  <b-form :id="id" :class="['controls-form', { 'is-inline': inline }]" novalidate v-on:submit.prevent>
    <div v-if="isLoading" class="form-busy">
      <loader :type="loaderType" />
    </div>

    <b-form-row v-else :class="{ [`align-items-${inlineAlignment}`]: inline }">
      <b-col :cols="inline ? 'auto' : 12">
        <b-form-row class="form-controls">
          <b-col v-for="(control, cKey) in form.controls" :key="cKey" :[$root.mt.key]="inline ? 'auto' : control._grid.cols">
            <component
              v-if="control.visible"
              :is="control.control.is"
              :class="{ 'mb-0': inline }"
              v-model="control.control.value"
              v-bind="control.control"
              @input="($value) => onChange(control, $value, 'input')"
              @change="($value) => onChange(control, $value, 'change')"
            />
          </b-col>
        </b-form-row>
      </b-col>

      <b-col v-if="showButtons" :cols="inline ? 'auto' : 12">
        <div class="form-actions">
          <div :class="`justify-content-${buttonAlignment}`">
            <b-button v-if="showAbortButton" :variant="form.actions.abort.variant" :size="form.actions.abort.size" @click="onAbort"
              ><slot name="abort">{{ $t(`${tPath}.abortbutton`) }}</slot></b-button
            >
            <b-button v-if="showResetButton" :variant="form.actions.reset.variant" :size="form.actions.reset.size" @click="onReset"
              ><slot name="reset">{{ $t(`${tPath}.resetbutton`) }}</slot></b-button
            >
            <b-button :variant="form.actions.submit.variant" :size="form.actions.submit.size" @click="onSubmit"
              ><slot name="submit">{{ $t(`${tPath}.submitbutton`) }}</slot></b-button
            >
          </div>
        </div>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { controlMapper, preProcessControls } from '@/assets/js/helper/entity'

import Loader from '@/components/private/Loader'
import ControlInput from '@/components/private/forms/ControlInput'
import ControlSelect from '@/components/private/forms/ControlSelect'
import ControlTextarea from '@/components/private/forms/ControlTextarea'
import ControlCheckbox from '@/components/private/forms/ControlCheckbox'
import ControlRadio from '@/components/private/forms/ControlRadio'
import ControlFile from '@/components/private/forms/ControlFile'
// import ControlFiles from '@/components/private/forms/ControlFiles'
import ControlDatepicker from '@/components/private/forms/ControlDatepicker'
import ControlSpinbutton from '@/components/private/forms/ControlSpinbutton'
import ControlAutocomplete from '@/components/private/forms/ControlAutocomplete'
import ControlUnknown from '@/components/private/forms/ControlUnknown'

export default {
  name: 'ControlsForm',
  components: {
    Loader,
    ControlInput,
    ControlSelect,
    ControlTextarea,
    ControlCheckbox,
    ControlRadio,
    ControlFile,
    // ControlFiles,
    ControlDatepicker,
    ControlSpinbutton,
    ControlAutocomplete,
    ControlUnknown
  },
  props: {
    isBusy: {
      type: Boolean,
      default: false
    },
    loaderType: {
      type: String,
      // visit @/components/Loader.vue for more informations
      default: 'border'
    },
    id: {
      type: String,
      default: 'ControlsForm'
    },
    controlDefinition: {
      type: Object,
      required: true,
      default: () => ({})
    },
    controlValues: {
      type: Object,
      default: () => ({})
    },
    triggerInit: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    inlineAlignment: {
      type: String,
      default: 'end'
    },
    labelCols: {
      type: Number,
      default: null
    },
    showButtons: {
      type: Boolean,
      default: true
    },
    showResetButton: {
      type: Boolean,
      default: false
    },
    showAbortButton: {
      type: Boolean,
      default: false
    },
    buttonAlignment: {
      type: String,
      default: 'end'
    },
    buttonVariants: {
      type: Object,
      default: () => ({})
    },
    buttonSizes: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: {
        controls: {},
        actions: {
          submit: {
            variant: this.$props.buttonVariants.submit || 'primary',
            size: this.$props.buttonSizes.submit || 'md'
          },
          reset: {
            variant: this.$props.buttonVariants.reset || 'control',
            size: this.$props.buttonSizes.reset || 'md'
          },
          abort: {
            variant: this.$props.buttonVariants.abort || 'outline-control',
            size: this.$props.buttonSizes.abort || 'md'
          }
        }
      }
    }
  },
  validations() {
    return {
      form: {
        controls: Object.keys(this.form.controls)
          .filter((cKey) => this.form.controls[cKey].visible)
          .reduce((controls, cKey) => Object.assign(controls, { [cKey]: { control: { value: this.form.controls[cKey].control.validations } } }), {})
      }
    }
  },
  computed: {
    isLoading() {
      return this.isBusy || this.$store.getters['validators/is'].loading
    },
    validators() {
      return this.$store.getters['validators/get']
    },
    formData() {
      return {
        id: this.id,
        controls: Object.keys(this.form.controls)
          .filter((cKey) => !this.form.controls[cKey].control.readonly)
          .reduce((formData, cKey) => Object.assign(formData, { [cKey]: this.form.controls[cKey].control.value }), {}),
        isValid: !this.$v.$invalid,
        isDirty: !this.$v.$dirty
      }
    }
  },
  methods: {
    createControls() {
      this.form.controls = controlMapper(this.controlDefinition, this.$store.getters['gui/language:get'], this.validators, this.controlValues)
      this.onInit()
      if (this.triggerInit) this.onChange()
    },
    touchControls() {
      Object.keys(this.form.controls).forEach((cKey) => {
        this.form.controls[cKey].change()
      })

      this.$v.$touch()
      this.$emit('form:touch', !this.$v.$invalid)
    },
    resetControls() {
      Object.keys(this.form.controls).forEach((cKey) => {
        this.form.controls[cKey].reset()
      })

      this.$v.$reset()
    },
    onInit() {
      this.$emit('form:init', this.formData)
      this.$emit('form:validation', { isDirty: this.$v.isDirty, isValid: this.$v.isValid })
    },
    onChange(control = null, value, eventType) {
      if (control) {
        control.control.value = value
        control.change()
      }

      if (eventType === 'change' || !this.lazy) {
        const formData = this.formData
        formData.controls = preProcessControls(this.form.controls, formData.controls)
        this.$emit('form:change', formData)
        this.$emit('form:validation', { isDirty: this.$v.isDirty, isValid: this.$v.isValid })
      }
    },
    onSubmit() {
      if (this.$v.$invalid) {
        this.touchControls()
        this.$emit('form:invalidsubmit', this.formData)
      } else {
        const formData = this.formData
        formData.controls = preProcessControls(this.form.controls, formData.controls)
        this.$emit('form:submit', formData)
        this.resetControls()
      }
    },
    onReset() {
      this.resetControls()
      this.$emit('form:reset')
    },
    onAbort() {
      this.resetControls()
      this.$emit('form:abort')
    }
  },
  created() {
    this.$root.$on(`${this.id}:validate`, this.touchControls)
    this.$root.$on(`${this.id}:reset`, this.onReset)

    this.createControls()
    this.$store.dispatch('validators/get')
  },
  watch: {
    validators() {
      this.createControls()
    },
    '$props.controlDefinition': {
      deep: true,
      handler() {
        this.controlDefinition = this.$props.controlDefinition
        this.createControls()
      }
    },
    '$props.controlValues': {
      deep: true,
      handler() {
        this.controlValues = this.$props.controlValues
        this.createControls()
      }
    }
  }
}
</script>

<style lang="scss">
// mobile

$controlsform-actions-gap: $spacer * 0.5 !default;
$controlsform-actions-button-gap: $spacer * 0.125 !default;

$controlsform-controls-input-inline-min-width: auto !default;
$controlsform-controls-textarea-inline-min-width: 280px !default;
$controlsform-controls-select-inline-min-width: auto !default;

// tablet

$controlsform-tablet: $tablet-breakpoint !default;

.controls-form {
  .form-busy {
  }

  .form-controls {
  }

  .form-actions {
    margin-top: $controlsform-actions-gap;

    > div {
      display: flex;
      align-items: center;
      margin: $controlsform-actions-button-gap * -1;

      .btn {
        margin: $controlsform-actions-button-gap;
      }
    }
  }

  &.is-inline {
    max-width: 100%;

    .form-controls {
      .control-input {
        min-width: $controlsform-controls-input-inline-min-width;
      }

      .control-textarea {
        min-width: $controlsform-controls-textarea-inline-min-width;
      }

      .control-select {
        min-width: $controlsform-controls-select-inline-min-width;
      }
    }

    .form-actions {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up($controlsform-tablet) {
    .form-controls {
      [class*='col-md-12'] {
        .form-group {
          .input-group {
            max-width: calc(50% - ($form-grid-gutter-width * 0.5));
          }
        }
      }
    }
  }
}
</style>
