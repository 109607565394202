<template>
  <nav :class="['checkout-navigation', { 'have-steps': haveNavigationSteps }]">
    <a :href="backlink.href" class="navigation-backlink"><lazy-icon icon="caret-left" scale="0.75"/> {{ backlink.text }}</a>

    <ul class="navigation-steps" v-if="haveNavigationSteps">
      <li v-for="(step, sIndex) in navigationSteps" :key="sIndex" :class="[{ 'active': step.active, 'current': step.current }]">
        <lazy-icon icon="caret-right" scale="0.6"/>
        <a v-if="step.active" v-bind="step" class="step">
          <span class="step-number">
            <span class="step-number-inner">
              <template v-if="step.current">{{ sIndex + 1 }}</template>
              <template v-else><lazy-icon icon="check-small" /></template>
            </span>
          </span> <span class="step-text">{{ step.text }}</span></a>
        <span v-else class="step">
          <span class="step-number"><span class="step-number-inner">{{ sIndex + 1 }}</span></span> <span class="step-text">{{ step.text }}</span>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'CheckoutNavigation',
  props: {
    backlink: {
      type: Object,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  computed: {
    stepValidationsLoaded () {
      return (this.$store.getters['shoppingcart/getValidationResult'].results || []).length > 0
    },
    navigationSteps () {
      const hasCurrent = this.steps.findIndex(s => s.current) >= 0

      let currentReached = false

      return this.steps
        .map(step => {
          const isActive = !currentReached ? hasCurrent : false

          if (step.current) {
            currentReached = true
          }

          return Object.assign({}, step, { active: isActive })
        })
    },
    haveNavigationSteps () {
      return (this.navigationSteps || []).length > 0
    }
  },
  methods: {
    stepsBeforeCurrentAreInvalidHandler () {
      if (this.stepValidationsLoaded) {
        const currentStep = this.navigationSteps.find(s => s.current)

        if (currentStep) {
          const currentStepIndex = this.navigationSteps.findIndex(s => s.current)
          const stepsBeforeCurrent = this.navigationSteps.filter((s, sIndex) => sIndex < currentStepIndex)
          const stepsBeforeCurrentAreValid = stepsBeforeCurrent.every(s => s.active)

          if (!stepsBeforeCurrentAreValid) {
            const lastActiveStepBeforeCurrent = stepsBeforeCurrent.filter(s => s.active).at(-1) || stepsBeforeCurrent[0]
            window.location.replace(lastActiveStepBeforeCurrent.href)
          }
        }
      }
    }
  },
  watch: {
    navigationSteps: {
      immediate: true,
      deep: true,
      handler () {
        this.stepsBeforeCurrentAreInvalidHandler()
      }
    }
  }
}
</script>

<style lang="scss">
// mobile
$checkoutnavigation-backlink-gap: $spacer !default;
$checkoutnavigation-backlink-font-size: $breadcrumb-font-size !default;
$checkoutnavigation-backlink-color: $gray-700 !default;

$checkoutnavigation-gap: $grid-gutter-width * 0.5 !default;
$checkoutnavigation-border: map-get($borders, 'base') !default;

$checkoutnavigation-steps-padding-y: $spacer !default;
$checkoutnavigation-steps-padding-x: 0 !default;

$checkoutnavigation-step-font-size: inherit !default;
$checkoutnavigation-step-font-weight: $font-weight-normal !default;
$checkoutnavigation-step-color: $dark !default;

$checkoutnavigation-step-active-font-size: $checkoutnavigation-step-font-size !default;
$checkoutnavigation-step-active-font-weight: $font-weight-normal !default;
$checkoutnavigation-step-active-color: $dark !default;

$checkoutnavigation-step-current-font-size: $checkoutnavigation-step-active-font-size !default;
$checkoutnavigation-step-current-font-weight: $font-weight-bold !default;
$checkoutnavigation-step-current-color: $body-color !default;

$checkoutnavigation-step-number-bg: transparent !default;
$checkoutnavigation-step-number-outline-width: 2px !default;
$checkoutnavigation-step-number-outline-color: $mud !default;
$checkoutnavigation-step-number-outline-offset: 0px !default;
$checkoutnavigation-step-number-size: $spacer * 1.4 !default;
$checkoutnavigation-step-number-font-size: $font-size-sm !default;
$checkoutnavigation-step-number-color: inherit !default;

$checkoutnavigation-step-active-number-bg: $primary !default;
$checkoutnavigation-step-active-number-outline-width: $checkoutnavigation-step-number-outline-width !default;
$checkoutnavigation-step-active-number-outline-color: transparent !default;
$checkoutnavigation-step-active-number-outline-offset: $checkoutnavigation-step-number-outline-width !default;
$checkoutnavigation-step-active-number-color: color-yiq($checkoutnavigation-step-active-number-bg) !default;

$checkoutnavigation-step-current-number-bg: $checkoutnavigation-step-active-number-bg !default;
$checkoutnavigation-step-current-number-outline-width: $checkoutnavigation-step-number-outline-width !default;
$checkoutnavigation-step-current-number-outline-color: $checkoutnavigation-step-current-number-bg !default;
$checkoutnavigation-step-current-number-outline-offset: $checkoutnavigation-step-number-outline-width !default;
$checkoutnavigation-step-current-number-color: color-yiq($checkoutnavigation-step-current-number-bg) !default;

$checkoutnavigation-step-text-gap: $spacer * 0.1 !default;
$checkoutnavigation-step-text-font-size: 10px !default;

$checkoutnavigation-step-separator-gap: 0 !default;
$checkoutnavigation-step-separator-color: $checkoutnavigation-step-color !default;

// tablet
$checkoutnavigation-tablet: $tablet-breakpoint !default;

$checkoutnavigation-tablet-step-text-gap: $spacer * 0.75 !default;
$checkoutnavigation-tablet-step-text-font-size: $font-size-sm !default;

$checkoutnavigation-tablet-step-separator-gap: $spacer * 0.5 !default;

// desktop
$checkoutnavigation-desktop: $desktop-breakpoint !default;

$checkoutnavigation-desktop-step-text-gap: $checkoutnavigation-tablet-step-text-gap !default;
$checkoutnavigation-desktop-step-text-font-size: $font-size-base !default;

$checkoutnavigation-desktop-step-separator-gap: $spacer * 0.75 !default;

.checkout-navigation {

  &.have-steps {
    margin-bottom: $checkoutnavigation-gap;
    border-bottom: $checkoutnavigation-border;
  }

  .navigation-backlink {
    display: inline-block;
    margin-bottom: $checkoutnavigation-backlink-gap;
    font-size: $checkoutnavigation-backlink-font-size;
    color: $checkoutnavigation-backlink-color;
  }

  .navigation-steps {
    @include list-unstyled();
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: $checkoutnavigation-steps-padding-y $checkoutnavigation-steps-padding-x;

    > li {
      display: flex;
      align-items: center;

      &:first-child {
        > .bi {
          display: none;
        }
      }

      .bi {
        margin-left: $checkoutnavigation-step-separator-gap;
        margin-right: $checkoutnavigation-step-separator-gap;
        fill: $checkoutnavigation-step-separator-color;
      }

      .step {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: $checkoutnavigation-step-font-size;
        font-weight: $checkoutnavigation-step-font-weight;
        color: $checkoutnavigation-step-color;
        text-decoration: none;

        .step-number {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: calc(#{$checkoutnavigation-step-active-number-outline-width} + #{$checkoutnavigation-step-active-number-outline-offset});

          font-size: $checkoutnavigation-step-number-font-size;
          color: $checkoutnavigation-step-number-color;
          border-radius: 50%;

          border: $checkoutnavigation-step-number-outline-width solid $checkoutnavigation-step-number-outline-color;

          .step-number-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $checkoutnavigation-step-number-size;
            height: $checkoutnavigation-step-number-size;
            background-color: $checkoutnavigation-step-number-bg;
            border-radius: 50%;

            .b-icon {
              fill: $checkoutnavigation-step-active-number-color;
            }
          }
        }

        .step-text {
          display: block;
          margin-top: $checkoutnavigation-step-text-gap;
          width: 100%;
          font-size: $checkoutnavigation-step-text-font-size;
          text-align: center;
        }
      }

      &.active {
        .step {
          font-size: $checkoutnavigation-step-active-font-size;
          font-weight: $checkoutnavigation-step-active-font-weight;
          color: $checkoutnavigation-step-active-color;

          .step-number {
            margin: calc(#{$checkoutnavigation-step-active-number-outline-width} + #{$checkoutnavigation-step-active-number-outline-offset});
            color: $checkoutnavigation-step-active-number-color;
            border: $checkoutnavigation-step-active-number-outline-width solid $checkoutnavigation-step-active-number-outline-color;
            padding: 2px;

            .step-number-inner {
              background-color: $checkoutnavigation-step-active-number-bg;
            }
          }
        }
      }

      &.current {
        .step {
          font-size: $checkoutnavigation-step-current-font-size;
          font-weight: $checkoutnavigation-step-current-font-weight;
          color: $checkoutnavigation-step-current-color;

          .step-number {
            margin: calc(#{$checkoutnavigation-step-current-number-outline-width} + #{$checkoutnavigation-step-current-number-outline-offset});
            color: $checkoutnavigation-step-current-number-color;
            border: $checkoutnavigation-step-current-number-outline-width solid $checkoutnavigation-step-current-number-outline-color;
            padding: 2px;

            .step-number-inner {
              background-color: $checkoutnavigation-step-current-number-bg;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up($checkoutnavigation-tablet) {
    .navigation-steps {
      > li {
        .bi {
          margin-left: $checkoutnavigation-tablet-step-separator-gap;
          margin-right: $checkoutnavigation-tablet-step-separator-gap;
        }

        .step {
          flex-wrap: nowrap;

          .step-text {
            margin-top: 0;
            margin-left: $checkoutnavigation-tablet-step-text-gap;
            width: auto;
            font-size: $checkoutnavigation-tablet-step-text-font-size;
          }
        }
      }
    }
  }

  @include media-breakpoint-up($checkoutnavigation-desktop) {
    .navigation-steps {
      > li {
        .bi {
          margin-left: $checkoutnavigation-desktop-step-separator-gap;
          margin-right: $checkoutnavigation-desktop-step-separator-gap;
        }

        .step {
          .step-text {
            margin-left: $checkoutnavigation-desktop-step-text-gap;
            font-size: $checkoutnavigation-desktop-step-text-font-size;
          }
        }
      }
    }
  }
}
</style>
